import styled from 'styled-components';

const SettingIOSConatiner = styled.div`
  @padding-left: 30px;
  @line-width: 1px;
  @circle-width: 9px;
  padding-left: @padding-left;
  margin-top: 20px;
  font-size: 14px;
  font-family: SVN-Gilroy;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  @media (max-width: 580px) {
    display: none;
  }

  .popupContent {
    gap: 20px;
    margin: auto;
    display: grid;
    color: #092f51;
    max-width: 546px;
    background: #ffff;
    margin-bottom: 20px;
    .changeBrowser {
      display: grid;
      gap: 20px;
      .step {
        display: flex;
        align-items: center;
        gap: 16px;
        position: relative;
        margin-left: -16px;
        border-radius: 4px;
        padding-right: 16px;
        p {
          margin: 0;
        }
        &:before {
          height: 32px;
          width: 32px;
          line-height: 20px;
          font-weight: 700;
          font-size: 14px;
          border-radius: 50%;
          background: #104370;
          color: #fafafa;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .step1 {
        padding-top: 16px;
        &:before {
          content: 'B1';
        }
      }
      .step2 {
        &:before {
          content: 'B2';
        }
      }
      .step3 {
        padding-bottom: 16px;
        &:before {
          content: 'B3';
        }
      }
      .step4 {
        padding-bottom: 18px;
        &:before {
          content: 'B4';
        }
      }
      .devider1 {
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 16px;
          width: 2px;
          height: 70px;
          background: #00b74f;
          top: 48px;
        }
      }
      .devider2 {
        display: grid;
        gap: 8px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 16px;
          width: 2px;
          height: 49px;
          background: #00b74f;
          top: 31px;
        }
      }
      .devider3 {
        p {
          margin: 0;
        }
      }
    }
    .deviderCol {
      height: 8px;
      background: #f2f5f6;
      width: 100%;
    }
    .groupBrowser {
      display: flex;
      align-items: center;
      gap: 40px;
      margin-top: 8px;
      .browser {
        display: flex;
        align-items: center;
        gap: 8px;
        img {
          height: 32px;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
    .copyLink {
      display: flex;
      align-items: normal;
      border: 1px solid #00b74f;
      justify-content: space-between;
      border-radius: 4px;
      height: 40px;
      .content {
        padding-right: 12px;
        display: flex;
        align-items: center;
        .icBrowser {
          height: 20px;
          width: 20px;
          margin-right: 8px;
        }
        .text {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          padding-left: 8px;
          color: #092f51;
        }
      }
      .btnConfirm {
        height: 40px;
        width: 40px;
        margin-right: 23px;
      }
      .btnContinue {
        width: 40px;
        display: flex;
        margin: auto;
        padding: 0;
        margin: 0;
        margin-right: -13px;
      }
    }
  }
`;

export default SettingIOSConatiner;
