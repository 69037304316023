import get from 'lodash/get';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { ROUTE, screenType } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { checkImageOCRFront } from './actions';

export function* checkImageOCRFrontSaga(action) {
  const payload = {
    url: CONSTANT.apiCommon.ocrScanFront,
    data: get(action, 'payload'),
  };
  yield put(checkImageOCRFront.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {

      switch (data.key) {
        case screenType.REJECT: {
          yield put(checkImageOCRFront.success());
          history.push(`${ROUTE.common.Reject}?id=reject_otp`);
          break;
        }
        case screenType.OCR_BACK: {
          yield put(checkImageOCRFront.success());
          history.push(`${ROUTE.common.OCRCaptureBack}`);
          break;
        }
        case 'RETRY': {
          return yield put(
            checkImageOCRFront.failure({
              message: get(
                data,
                'message',
                'Ảnh của bạn không đủ chất lượng. Vui lòng bấm “Chụp lại” để thử lại.',
              ),
            }),
          );
        }
        default: {
          yield put(
            checkImageOCRFront.failure({
              message: get(
                data,
                'message',
                'Ảnh của bạn không đủ chất lượng. Vui lòng bấm “Chụp lại” để thử lại.',
              ),
            }),
          );
          toast.error(get(data, 'message', MESSAGE_SYSTEM.error_01));
          break;
        }
      }
      return;
    }

    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
    yield put(
      checkImageOCRFront.failure({
        message: get(error, 'meta.message', MESSAGE_SYSTEM.default),
      }),
    );
  }
}

export default function* watchAll() {
  yield all([takeLatest(checkImageOCRFront.TRIGGER, checkImageOCRFrontSaga)]);
}
