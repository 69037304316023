import get from 'lodash/get';
import { produce } from 'immer';

import { getCardListBenefit, getListCards } from './actions';

export const initialState = {
  loadingbenefits: false,
  loadingListCard: false,
  listBenefits: [],
  listCardOffer: [],
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getCardListBenefit.REQUEST:
        draft.loadingbenefits = true;
        break;
      case getCardListBenefit.SUCCESS:
        draft.loadingbenefits = false;
        draft.listBenefits = get(action, 'payload.data.data');
        break;
      case getCardListBenefit.FAILURE:
        draft.loadingbenefits = false;
        break;
      case getListCards.REQUEST:
        draft.loadingListCard = true;
        break;
      case getListCards.SUCCESS:
        draft.loadingListCard = false;
        draft.listCardOffer = get(action, 'payload.data.data');

        break;
      case getListCards.FAILURE:
        draft.loadingListCard = false;
        break;
    }
  });

export default appReducer;
