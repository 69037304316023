import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { screenType, ROUTE } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { getMaritals, submitAdditionalInfo } from './actions';

export function* getMaritalsSaga() {
  const payload = {
    url: CONSTANT.apiCommon.selections,
    params: { category: 'MARITALSTATUS' },
  };

  yield put(getMaritals.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(
        getMaritals.success({ listMarital: isArray(data) ? data : [] }),
      );
      return;
    }

    yield put(getMaritals.failure());
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(getMaritals.failure());
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export function* submitAdditionalInfoSaga(action) {
  const payload = {
    url: CONSTANT.apiCommon.addPaInfo,
    data: get(action, 'payload'),
  };

  yield put(submitAdditionalInfo.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (
      get(meta, 'code') === 'IL-200' &&
      get(data, 'screen') === screenType.WAITING_LOS_RISK_ASSETMENT
    ) {
      history.push(ROUTE.common.WattingLosRiskAssetment);
      return;
    }

    yield put(submitAdditionalInfo.success());
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(submitAdditionalInfo.failure());
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(getMaritals.TRIGGER, getMaritalsSaga),
    takeLatest(submitAdditionalInfo.TRIGGER, submitAdditionalInfoSaga),
  ]);
}
