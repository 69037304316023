import React from 'react';
import loadable from '@utils/loadable';

import LoadingIndicator from '@components/commons/LoadingIndicator';

const Provider = ({ children }) => <>{children}</>;

const CompletedUpl = loadable(() => import('./Main'), {
  fallback: <LoadingIndicator />,
  provider: Provider,
});

export default CompletedUpl;
