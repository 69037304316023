/* eslint-disable prettier/prettier */
import { createRoutine } from 'redux-saga-routines';

import {
  GET_FIRST_DATE_DEBT,
  GET_INFO_LOAN_OFFER,
  SUBMIT_LOAN_OFFER,
} from './constants';

export const getInfoLoanOfferAction = createRoutine(GET_INFO_LOAN_OFFER);
export const getFirstDatedebtAction = createRoutine(GET_FIRST_DATE_DEBT);
export const submitLoanOfferAction = createRoutine(SUBMIT_LOAN_OFFER);
