/*
 *
 * Lady Mastercard reducer
 *
 */
import { produce } from 'immer';
import get from 'lodash/get';

import { resendOTPAction, verifyOTPAction } from './actions';

export const initialState = {
  loading: false,
  msgError: null,
  flagError: false,
  codeErr: null,
  appIdOtp: null,
};

/* eslint-disable default-case, no-param-reassign */
const OtpReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case resendOTPAction.REQUEST: {
        draft.loading = true;
        draft.flagError = false;
        draft.msgError = null;
        draft.codeErr = null;

        break;
      }
      case resendOTPAction.SUCCESS: {
        draft.loading = false;
        draft.flagError = false;
        draft.msgError = null;
        draft.codeErr = null;
        break;
      }
      case resendOTPAction.FAILURE: {
        draft.loading = false;
        draft.msgError = action.payload.message;
        draft.flagError = true;
        draft.codeErr = action.payload.error;
        break;
      }
      case verifyOTPAction.REQUEST: {
        draft.loading = true;
        draft.flagError = false;

        break;
      }
      case verifyOTPAction.SUCCESS: {
        draft.loading = false;
        draft.flagError = false;
        break;
      }
      case verifyOTPAction.FAILURE: {
        draft.loading = false;
        draft.msgError = get(action, 'payload.meta.message');
        draft.codeErr = action.payload.meta.code;
        draft.flagError = true;
        break;
      }
    }
  });

export default OtpReducer;
