import styled from 'styled-components';

export const FormGuideSettingContainer = styled.div`
  margin: auto;
  display: grid;
  color: #092f51;
  max-width: 580px;
  margin-top: 42px;
  margin-bottom: 72px;
  @media (max-width: 580px) {
    margin-top: 20px;
    padding: 16px;
    margin-bottom: 20px;
    padding-bottom: 0;
    margin: 0 16px 0 16px;
  }

  @media (max-width: 376px) {
    margin-top: 20px;
    margin: 0;
  }

  .content {
    gap: 14px;
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
    @media (max-width: 580px) {
      margin-bottom: 20px;
    }

    img {
      width: 328px;
      height: 200px;
      margin: auto;
    }
    .subTitle {
      margin: 0;
      font-family: SVN-Gilroy;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      @media (max-width: 580px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .btnConfirm {
    margin: auto;
    width: 380px;
    height: 48px;
    margin-top: 20px;
    @media (max-width: 580px) {
      width: 100%;
    }

    button {
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
    }
  }
`;
