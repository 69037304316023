import { produce } from 'immer';
import get from 'lodash/get';
import {
  getBranchProvince,
  submitDeliveryAddress,
  submitDeliveryBranch,
} from './actions';

export const initialState = {
  loadingPage: false,
  listBranch: [],
};

/* eslint-disable default-case, no-param-reassign */
const DeliveryAddressReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getBranchProvince.REQUEST: {
        draft.loadingPage = true;
        break;
      }
      case getBranchProvince.SUCCESS: {
        draft.loadingPage = false;
        draft.listBranch = get(action, 'payload.data.data');
        break;
      }
      case getBranchProvince.FAILURE: {
        draft.loadingPage = false;
        break;
      }
      case submitDeliveryAddress.REQUEST: {
        draft.loadingPage = true;
        break;
      }
      case submitDeliveryAddress.SUCCESS: {
        draft.loadingPage = false;
        break;
      }
      case submitDeliveryAddress.FAILURE: {
        draft.loadingPage = false;
        break;
      }
      case submitDeliveryBranch.REQUEST: {
        draft.loadingPage = true;
        break;
      }
      case submitDeliveryBranch.SUCCESS: {
        draft.loadingPage = false;
        break;
      }
      case submitDeliveryBranch.FAILURE: {
        draft.loadingPage = false;
        break;
      }
    }
  });
export default DeliveryAddressReducer;
