/*
 *
 * Lady Mastercard reducer
 *
 */
import { produce } from 'immer';

import get from 'lodash/get';
import { waitingCreateAppInTp } from './actions';

export const initialState = {
  loading: false,
  retryCallWS: null,
};

/* eslint-disable default-case, no-param-reassign */
const WattingVideoCallReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case waitingCreateAppInTp.REQUEST: {
        draft.loading = true;
        break;
      }
      case waitingCreateAppInTp.SUCCESS: {
        draft.loading = false;
        draft.retryCallWS = get(action, 'payload.data.data.screen');
        break;
      }
      case waitingCreateAppInTp.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });
export default WattingVideoCallReducer;
