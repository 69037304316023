import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import { checkSafari } from '@utils/helpers';

import { titleModal, INTRODUCTION, OUTSIDE_WORK_HOUR } from './constants';
import { getLinkWS6Upl, checkOutsideWorkHourUpl } from './actions';

export function* checkOutsideWorkHourUplSaga() {
  const payload = {
    url: CONSTANT.apiCommon.validationTimeWork,
    params: { productType: 'UPL' },
  };

  yield put(checkOutsideWorkHourUpl.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200') {
      yield put(
        checkOutsideWorkHourUpl.success({
          layout: data ? INTRODUCTION : OUTSIDE_WORK_HOUR,
        }),
      );
      return;
    }
    yield put(checkOutsideWorkHourUpl.failure());
  } catch (error) {
    yield put(checkOutsideWorkHourUpl.failure());
  }
}

export function* getLinkWS6UplSaga(action) {
  const payload = {
    url: CONSTANT.apiCommon.getLinkWs6,
    data: get(action, 'payload'),
  };

  yield put(
    getLinkWS6Upl.request({
      isConnectingLink: true,
      title: titleModal.connect,
    }),
  );
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200') {
      switch (get(data, 'key')) {
        case 'VIDEO_CALL_TPC': {
          yield put(getLinkWS6Upl.success());

          const isSafari = checkSafari();
          if (isSafari) {
            window.location.href = data.message;
            return;
          }
          window.open(data.message, '_blank');
          return;
        }
        case 'RETRY': {
          yield put(
            getLinkWS6Upl.failure({
              isRetryLink: true,
              title: titleModal.fail_2,
            }),
          );
          return;
        }
        default: {
          break;
        }
      }
    }

    yield put(
      getLinkWS6Upl.failure({
        isRetryLink: true,
        title: titleModal.fail_1,
      }),
    );
  } catch (error) {
    if (get(error, 'meta.code') === 'IL-9898') {
      yield put(
        getLinkWS6Upl.failure({
          isOverLimitLink: true,
          title: titleModal.fail_2,
        }),
      );
      return;
    }

    yield put(
      getLinkWS6Upl.failure({
        isRetryLink: true,
        title: titleModal.fail_1,
      }),
    );
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(getLinkWS6Upl.TRIGGER, getLinkWS6UplSaga),
    takeLatest(checkOutsideWorkHourUpl.TRIGGER, checkOutsideWorkHourUplSaga),
  ]);
}
