import { produce } from 'immer';

import { confirmLoanProfile } from './actions';

export const initialState = {
  loadingAction: false,
};

/* eslint-disable default-case, no-param-reassign */
const CardProfileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case confirmLoanProfile.REQUEST: {
        draft.loadingAction = true;
        break;
      }
      case confirmLoanProfile.SUCCESS: {
        draft.loadingAction = false;
        break;
      }
      case confirmLoanProfile.FAILURE: {
        draft.loadingAction = false;
        break;
      }
    }
  });

export default CardProfileReducer;
