import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import get from 'lodash/get';

import * as Api from '@utils/services/api.request';
import * as CONST_GLB from '@utils/services/api.constants';

import { getAddress } from './actions';

export function* getAddressSaga(action) {
  const payload = {
    url: CONST_GLB.apiCc.findByProvinceCode,
    params: get(action, 'payload'),
  };

  yield put(getAddress.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(getAddress.success(data));
    } else {
      toast.error(get(meta, 'message') || 'error system');
      yield put(getAddress.success());
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getAddress.failure());
  }
}

export default function* watchAll() {
  yield all([takeLatest(getAddress.TRIGGER, getAddressSaga)]);
}
