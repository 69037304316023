import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import { getBenefits } from './actions';

export function* getBenefitsSaga() {
  const payload = {
    url: CONSTANT.apiCc.benefits,
    params: null,
  };

  yield put(getBenefits.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      const listBenefit = [];
      const dataType = uniqBy(data, 'type') || {};

      dataType.forEach(elem => {
        const listBenefitFollowType = data.filter(
          bene => bene.type === elem.type,
        );

        listBenefit.push({
          data: listBenefitFollowType,
          title: get(listBenefitFollowType, '[0].category_name') || '',
          orderCategory: get(listBenefitFollowType, '[0].order_category'),
        });
      });

      yield put(
        getBenefits.success({
          listBenefit: orderBy(listBenefit, ['orderCategory'], ['asc']),
        }),
      );
    } else {
      toast.error(get(meta, 'message') || 'error system');
      yield put(getBenefits.success());
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getBenefits.failure());
  }
}

export default function* watchAll() {
  yield all([takeLatest(getBenefits.TRIGGER, getBenefitsSaga)]);
}
