import styled from 'styled-components';

export const HeaderWraper = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'checkPath', // Ngừng truyền `checkPath` xuống DOM
})`
  text-decoration: none;
  top: 0;
  display: flex;
  margin: auto;
  justify-content: center;
  @media (max-width: 899px) {
    width: 100%;
    padding-top: 30px;
    z-index: 10;
  }
  @media (min-width: 900px) {
    width: 100%;
    padding-top: 16px;
    z-index: 999;
  }
  @media (max-width: 580px) {
    position: ${props => props.checkPath && 'absolute'};
  }
  .header {
    display: flex;
    align-items: center;
    @media (max-width: 899px) {
      width: 100%;
      margin-left: 16px;
    }
    @media (min-width: 900px) {
      width: 800px;
    }
  }
  .logo {
    @media (max-width: 899px) {
      width: 86px;
      height: 20px;
    }
    @media (min-width: 900px) {
      width: 138px;
      height: 32px;
      margin: 14px 0;
    }
  }
  .supportIcon2 {
    width: 64px;
    height: 64px;
    @media (max-width: 899px) {
      width: 70px;
      height: 70px;
    }
    @media (min-width: 900px) {
      width: 80px;
      height: 80px;
    }
  }
`;
