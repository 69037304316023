import { produce } from 'immer';

import get from 'lodash/get';
import { getCardDetail } from './actions';

export const initialState = {
  loadingCard: false,
  cardDetail: {},
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  // eslint-disable-next-line consistent-return
  produce(state, draft => {
    switch (action.type) {
      case getCardDetail.REQUEST:
        draft.loadingCard = true;
        break;
      case getCardDetail.SUCCESS:
        draft.loadingCard = false;
        draft.cardDetail = get(action, 'payload.data.data');

        break;
      case getCardDetail.FAILURE:
        draft.loadingCard = false;
        break;
    }
  });
export default appReducer;
