import styled from 'styled-components';

const Styled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .MuiLinearProgress-root {
    height: 6px;
    background-color: #ceddf3;
    @media (max-width: 680px) {
      height: 2px;
    }

    .MuiLinearProgress-bar {
      background-color: #00b74f;
    }
  }
`;

export default Styled;
