import { produce } from 'immer';
import get from 'lodash/get';
import { getCutOfftimeAction, getLinkThinhPhat } from './actions';
import { RETRY, VIDEO_CALL_TPC } from './constants';

export const initialState = {
  layout: '',

  loading: false,
  keyWaiting: null,
  loadingCallTP: false,
  callFaild: false,
};

/* eslint-disable default-case, no-param-reassign */
const VideoCallReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getCutOfftimeAction.REQUEST: {
        draft.layout = '';
        draft.loading = true;
        break;
      }
      case getCutOfftimeAction.SUCCESS: {
        draft.loading = false;
        draft.layout = get(action, 'payload.layout', '');
        break;
      }
      case getCutOfftimeAction.FAILURE: {
        draft.loading = false;
        break;
      }

      case getLinkThinhPhat.REQUEST: {
        draft.loadingCallTP = true;
        draft.callFaild = false;

        break;
      }
      case getLinkThinhPhat.SUCCESS: {
        draft.callFaild = false;
        if (get(action, 'payload.data.data.key') === VIDEO_CALL_TPC) {
          draft.loadingCallTP = true;
        } else if (get(action, 'payload.data.data.key') === RETRY) {
          draft.loadingCallTP = false;
        }
        draft.keyWaiting = get(action, 'payload.data.data.key');
        break;
      }
      case getLinkThinhPhat.FAILURE: {
        draft.loadingCallTP = false;
        draft.callFaild = true;
        draft.keyWaiting = null;
        break;
      }
    }
  });
export default VideoCallReducer;
