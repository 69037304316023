import styled from 'styled-components';

export const FooterWraper = styled.div`
  background: #ffffff;
  @media (max-width: 899px) {
    display: none;
  }
  width: 100%;
  @media (min-width: 900px) {
    display: flex;
    width: 100%;
  }
  .headLeft {
    flex-wrap: wrap;
    margin: 0 auto;
    width: 800px;
    display: flex;
    @media (min-width: 900px) {
      width: 100%;
    }
    @media (max-width: 899px) {
      width: 100%;
    }
  }
`;

export const FooterFirst = styled.div`
  display: flex;
  margin: 0 auto;
  @media (max-width: 899px) {
    width: 100%;
  }
  .footerFirstLayout {
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    @media (max-width: 580px) {
      margin: 16px auto;
      flex-wrap: wrap;
    }
    @media (min-width: 900px) {
      width: '800px';
    }
  }
  .footerFirstLayout1 {
    display: flex;
    .logo1 {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 580px) {
        padding: 8px;
      }
      @media (min-width: 1200px) {
        width: 20%;
      }
      .logo1Img {
        @media (min-width: 900px) {
          width: 138px;
          height: 32px;
        }
      }
    }
    .vertical {
      border: 1px solid #dfdfdf;
      transform: rotate(90deg);
      height: 1px;
      margin: auto;
      @media (max-width: 580px) {
        width: 32px;
      }
      @media (min-width: 900px) {
        width: 48px;
      }
    }

    .footerNone {
      @media (max-width: 580px) {
        display: none;
      }
    }
  }
  .footer1 {
    @media (min-width: 900px) {
      margin-left: 24px;
      margin-top: 16px;
    }
    .title1 {
      align-items: start;
      color: #333;
      font-size: 14px;
      font-family: SVN-Gilroy;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 2px;
      @media (min-width: 900px) {
        text-align: left;
        font-size: 14px;
      }
      @media (max-width: 580px) {
        text-align: left;
        font-size: 11px;
      }
    }
    .title2 {
      font-family: SVN-Gilroy;
      justify-content: start;
      align-items: start;
      font-size: 14px;
      color: #333;
      font-weight: 700;
      line-height: 24px;
      @media (min-width: 900px) {
        margin-bottom: 14px;
      }
      @media (max-width: 580px) {
        text-align: left;
        font-size: 11px;
      }
    }
    .textBlod {
      font-weight: 700;
    }
  }
`;

export const EndofFooter = styled.div`
  font-weight: light;
  @media (min-width: 900px) {
    display: flex;
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, #1d4289 0%, #00b74f 76.56%);
    font-size: 14px;
    color: #ffffff;
    font-weight: lighter;
  }
  @media (max-width: 900px) {
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, #1d4289 0%, #00b74f 76.56%);
    font-size: 11px;
    color: #ffffff;
    font-weight: normal;
    padding: 8px 0;
    text-align: center;
  }
  @media (max-width: 580px) {
    width: 100%;
    height: 56px;
    background: #ffffff;
    font-size: 11px;
    color: #334433;
    font-weight: normal;
    padding: 8px 0;
    text-align: center;
  }
  .footerWrap {
    display: flex;
    width: 800px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 580px) {
      width: 100%;
      box-shadow: inset 0px 1px 0px #dfdfdf;
      paddingtop: 16px;
    }
    @media (min-width: 900px) {
      margin: 0 auto;
    }
    .endofFooter1 {
      @media (max-width: 899px) {
        width: 100%;
        margin: 0 auto;
      }
      @media (min-width: 900px) {
        width: 100%;
        margin: 0 auto;
        text-align: left;
      }
      @media (max-width: 580px) {
        width: auto;
        margin: 0 0 0 16px;
      }
    }
    .endofFooter2 {
      text-decoration: none;
      color: #ffffff;
      :hover {
        text-decoration: none;
        color: #ffffff;
      }
      :link {
        color: #ffffff;
        text-decoration: none;
      }
      :visited {
        color: #ffffff;
        text-decoration: none;
      }
      @media (max-width: 580px) {
        width: auto;
        margin: 0 16px 0 0;
      }
      @media (min-width: 900px) {
        width: 100%;
        margin: 0 auto;
      }
      @media (min-width: 1200px) {
        width: 100%;
        margin: 0 auto;
        text-align: right;
      }
    }
  }
`;
