import { all, call, put, takeLatest } from "redux-saga/effects";

import get from "lodash/get";
import * as Api from "@utils/services/api.request";
import * as CONSTANT from "@utils/services/api.constants";

import { getInfoLimitCard, getPriorityCardAction } from "./actions";

export function* getPriorityCardSaga() {
  const appId = localStorage.getItem("appId");
  if (appId) {
    const payload = {
      url: CONSTANT.apiCc.cardConfigRule,
      params: { "app-id": appId }
    };

    yield put(getPriorityCardAction.request());
    try {
      const respond = yield call(Api.get, payload);
      yield put(getPriorityCardAction.success(respond));
    } catch (error) {
      yield put(getPriorityCardAction.failure(error));
    }
  }
}

export function* getInfoLimitCardSaga(action) {
  const appId = get(action, "payload.appId") || localStorage.getItem("appId");

  if (appId) {
    const payload = {
      url: CONSTANT.apiCommon.applicationDetail(appId),
      params: null
    };

    yield put(getInfoLimitCard.request());
    try {
      const respond = yield call(Api.get, payload);
      const { data, meta } = get(respond, "data") || {};

      if (get(meta, "code") === "IL-200" && data) {
        yield put(getInfoLimitCard.success({ appDetail: data || {} }));
        return;
      }

      yield put(getInfoLimitCard.failure());
      toast.error(get(data, "message", MESSAGE_SYSTEM.error_02));
    } catch (error) {
      toast.error(get(error, "meta.message", MESSAGE_SYSTEM.default));
      yield put(getInfoLimitCard.failure());
    }
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(getPriorityCardAction.TRIGGER, getPriorityCardSaga),
    takeLatest(getInfoLimitCard.TRIGGER, getInfoLimitCardSaga)
  ]);
}
