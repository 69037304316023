export const key = 'upl_vkyc_connection';

export const INTRODUCTION = 'INTRODUCTION';
export const OUTSIDE_WORK_HOUR = 'OUTSIDE_WORK_HOUR';
export const titleModal = {
  fail_1: 'Kết nối không thành công',
  fail_2: 'Hiện tất cả các Tổng đài viên đều bận',
  connect: 'Hệ thống đang kết nối với tổng đài viên',
};

export const GET_LINK_WS6_UPL = 'app/upl/vkyc/GET_LINK_WS6';

export const CHECK_OUTSIDE_WORK_HOUR_UPL =
  'app/upl/vkyc/CHECK_OUTSIDE_WORK_HOUR';
