import React, { useEffect, useState } from 'react';

import Loading from '@components/Loading';

const NotFound = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = window.location.href;

    if (url.includes("/api/gateway/v2/public/storage-file/download-contract/")) {
      setLoading(true);

      window.location.reload(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    } else {
      setLoading(false);
    }

  }, []);

  return (
    <React.Fragment>
      <Loading loading={loading} />
      {!loading && '404 Not Found'}
    </React.Fragment>
  )
};

export default NotFound;
