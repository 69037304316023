export const key = 'liveness';
export const typeImage = { type: 'image/jpeg' };
export const captureFrameSettings = {
  enable: true,
  framesIntervalTime: 180,
  framesBatchLength: 10,
};

// redux
export const GET_SETTING_SDK_TS = 'app/liveness/GET_SETTING_SDK_TS';

export const CHECK_DATA_LIVENESS = 'app/liveness/CHECK_DATA_LIVENESS';
// END redux
