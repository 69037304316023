import { jwtDecode } from "jwt-decode";
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import history from '@utils/history';
import * as COOKIE from '@utils/cookie';
import { nextScreen } from '@utils/redirect';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { ROUTE, TOKEN_KEY, screenType } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { resendOTPAction, verifyOTPAction } from './actions';

export function* resendOTPActionSaga(action) {
  const { phoneNumber } = action.payload;
  const payload = {
    params: { phoneNumber },
    url: CONSTANT.apiCommon.otpRequest,
  };

  yield put(resendOTPAction.request());
  try {
    const respond = yield call(Api.get, payload);
    yield put(resendOTPAction.success(respond));
  } catch (error) {
    yield put(resendOTPAction.failure(error));
  }
}

export function* verifyOTPActionSaga(action) {
  const { phoneNumber, otpCode, anonymous } = action.payload;
  const payload = {
    url: CONSTANT.apiCommon.otpVerify,
    data: {
      otpCode,
      phoneNumber,
      anonymous: get(anonymous, 'isPrivate') ? 1 : 0,
    },
  };

  yield put(verifyOTPAction.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      const appId = get(jwtDecode(get(respond, 'data.data.token')), 'appId');
      const type = get(jwtDecode(get(respond, 'data.data.token')), 'type');

      localStorage.setItem('appId', appId);
      localStorage.setItem('productType', type);
      COOKIE.setCookie(TOKEN_KEY, data.token, 30);
      localStorage.setItem(TOKEN_KEY, `Bearer ${data.token}`);

      yield put(verifyOTPAction.success());
      switch (get(respond, 'data.data.key')) {
        case screenType.REJECT: {
          history.push(ROUTE.common.Reject);
          break;
        }
        case screenType.ETC: {
          history.push(ROUTE.cc.EtcConfirm);
          break;
        }
        case screenType.INTRODUCTION: {
          history.push(ROUTE.cc.Introduction);
          break;
        }
        case screenType.WAITING_RESULT_OTP: {
          history.push(ROUTE.common.WaitingCreateAppLos);
          break;
        }
        case screenType.CARD_PROFILE: {
          history.push(ROUTE.cc.CardProfile);
          break;
        }
        case screenType.OCR_GUIDE: {
          history.push(ROUTE.common.OCRInstruction);
          break;
        }
        case screenType.OVERDUE: {
          history.push(ROUTE.upl.Overdue);
          break;
        }
        case screenType.OUT_SIDE_WORKING_HOUR: {
          history.push(ROUTE.upl.OutSideWorkingHour);
          break;
        }
        case screenType.RE_LOGIN: {
          yield put(verifyOTPAction.success());
          yield fork(handleRequestRelogin, { appId });
          return;
        }
        case 'REDIRECT_TP': {
          window.location.href = get(respond, 'data.data.url');
          return;
        }
        case 'REJECT_CC_NEO': {
          history.push(`${ROUTE.common.RejectNEO}?type=CCPA`);
          return;
        }
        default: {
          toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_01));
          break;
        }
      }
    }
  } catch (error) {
    yield put(verifyOTPAction.failure(error));
  }
}

function* handleRequestRelogin({ appId = '' }) {
  if (appId) {
    const payload = {
      url: CONSTANT.apiCommon.applicationDetail(appId),
    };

    yield put(verifyOTPAction.request());
    try {
      const respond = yield call(Api.get, payload);
      const { data, meta } = get(respond, 'data') || {};
      if (get(meta, 'code') === 'IL-200' && data) {
        nextScreen({ screen: get(data, 'screen_type') });
        return;
      }

      toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
    } catch (error) {
      toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
    } finally {
      yield put(verifyOTPAction.success());
    }
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(resendOTPAction.TRIGGER, resendOTPActionSaga),
    takeLatest(verifyOTPAction.TRIGGER, verifyOTPActionSaga),
  ]);
}
