/* eslint-disable prettier/prettier */
export const GET_COUNTRY = 'app/App/GET_COUNTRY';

export const GET_INFO_CUSTOMER_DETAIL = 'app/App/GET_INFO_CUSTOMER_DETAIL';

export const UPDATE_APPLICATION = 'app/App/UPDATE_APPLICATION';

export const GET_CHECK_CA = 'app/App/GET_CHECK_CA';

export const GET_CARD_DESIGN = 'app/App/GET_CARD_DESIGN';

export const CREATE_NICE_DIGITAL_ACCOUNT =
  'app/App/CREATE_NICE_DIGITAL_ACCOUNT';

export const GET_TOWERS = 'app/App/GET_TOWERS';

export const GET_DISTRICTS = 'app/App/GET_DISTRICTS';

export const GET_PROVINCES = 'app/App/GET_PROVINCES';

export const GET_SETTING_SDK_OCR = 'app/App/GET_SETTING_SDK_OCR';

export const CLOSE_MODAL_CHECK_CA = 'app/App/CLOSE_MODAL_CHECK_CA';

export const GET_WORK_TIME_APP = 'app/App/GET_WORK_TIME_APP';
