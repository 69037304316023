import React from 'react';

import SettingIOSConatiner from './Styled';

export default function SettingIOS({ isIOS }) {
  return (
    <SettingIOSConatiner>
      <div className="popupContent">
        <div className="changeBrowser">
          <div className="step step1">
            <div className="devider1">
              <p>Thoát trình duyệt hiện tại</p>
            </div>
          </div>
          <div className="deviderCol" />
          <div className="step step2">
            <div className="devider2">
              <p>
                Truy cập cài đặt hệ thống &gt;{' '}
                {isIOS ? 'Chọn quyền riêng tư và bảo mật' : 'Chọn riêng tư'}
                &nbsp; &gt; Chọn Camera
              </p>
            </div>
          </div>
          <div className="deviderCol" />
          <div className="step step3">
            <div className="devider3">
              <p>
                Chọn cấp quyền truy cập cho trình duyệt mà bạn đang thực hiện
                đăng ký
              </p>
            </div>
          </div>
        </div>
      </div>
    </SettingIOSConatiner>
  );
}
