import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import * as Api from '@utils/services/api.request';
import { screenType, ROUTE } from '@utils/constants';
import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as CONSTANT from '@utils/services/api.constants';

import {
  getBranchProvince,
  submitDeliveryAddress,
  submitDeliveryBranch,
} from './actions';

export function* getBranchProvinceSaga(action) {
  const { province, district } = get(action, 'payload');
  const payload = {
    url: CONSTANT.apiCc.deliveryBranch,
    params: {
      province_code: province,
      district_code: district,
    },
  };

  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(getBranchProvince.success(respond));
      return;
    }

    yield put(getBranchProvince.failure());
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(getBranchProvince.failure(error));
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export function* submitDeliveryAddressSaga(action) {
  const payload = {
    url: CONSTANT.apiCc.deliveryBranchLiness,
    data: get(action, 'payload'),
  };

  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (
      get(meta, 'code') === 'IL-200' &&
      get(data, 'screen') === screenType.WAITING_CREATE_APP_IN_TP
    ) {
      history.push(ROUTE.cc.WaitingProcessGenForm);
      yield put(submitDeliveryAddress.success(respond));
      return;
    }

    yield put(submitDeliveryAddress.failure());
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(submitDeliveryAddress.failure(error));
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export function* submitDeliveryBranchSaga(action) {
  const payload = {
    url: CONSTANT.apiCc.deliveryBranchPhysical,
    data: get(action, 'payload'),
  };

  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (
      get(meta, 'code') === 'IL-200' &&
      get(data, 'screen') === screenType.WAITING_CREATE_APP_IN_TP
    ) {
      history.push(ROUTE.cc.WaitingProcessGenForm);
      yield put(submitDeliveryBranch.success(respond));
      return;
    }

    yield put(submitDeliveryBranch.failure());
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(submitDeliveryBranch.failure(error));
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(getBranchProvince.TRIGGER, getBranchProvinceSaga),
    takeLatest(submitDeliveryBranch.TRIGGER, submitDeliveryBranchSaga),
    takeLatest(submitDeliveryAddress.TRIGGER, submitDeliveryAddressSaga),
  ]);
}
