import { produce } from 'immer';
import get from 'lodash/get';

import { getBenefits } from './actions';

export const initialState = {
  loading: false,
  listBenefit: [],
  loadingPage: true,
};

/* eslint-disable default-case, no-param-reassign */
const CardProfileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getBenefits.REQUEST: {
        draft.loading = true;
        break;
      }
      case getBenefits.SUCCESS: {
        draft.loading = false;
        draft.loadingPage = false;
        draft.listBenefit = get(action, 'payload.listBenefit') || [];
        break;
      }
      case getBenefits.FAILURE: {
        draft.loading = false;
        draft.loadingPage = false;
        break;
      }
    }
  });

export default CardProfileReducer;
