import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { ROUTE, screenType } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { getRelationshipsUPL, submitCompletepProfileAction } from './actions';

export function* getRelationshipsUPLSaga() {
  const payload = {
    url: CONSTANT.apiCommon.selections,
    params: { category: 'RELATIONSHIP' },
  };

  yield put(getRelationshipsUPL.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(
        getRelationshipsUPL.success({
          listRelationshipUPL: isArray(data) ? data : [],
        }),
      );
    } else {
      yield put(getRelationshipsUPL.failure());
      toast.error(get(meta, 'message') || 'error system');
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getRelationshipsUPL.failure());
  }
}

export function* submitCompleteProfileSaga(body) {
  const payload = {
    url: CONSTANT.apiUpl.submitCompleteProfile,
    data: get(body, 'payload', {}),
  };

  yield put(submitCompletepProfileAction.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200') {
      yield put(submitCompletepProfileAction.success());
      switch (get(data, 'screen')) {
        case screenType.WAITING_PROCCESING_GEN_ECONTRACT: {
          history.push(ROUTE.upl.WaitingProcessGenForm);
          break;
        }
        default: {
          toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_01));
          break;
        }
      }
      return;
    }

    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
    yield put(submitCompletepProfileAction.failure());
  } catch (error) {
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
    yield put(submitCompletepProfileAction.failure());
  }
}

export default function* watchAll() {
  yield all([takeLatest(getRelationshipsUPL.TRIGGER, getRelationshipsUPLSaga)]);
  yield all([
    takeLatest(submitCompletepProfileAction.TRIGGER, submitCompleteProfileSaga),
  ]);
}
