import styled from 'styled-components';

const MainTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #104370;
  text-align: center;
  @media (max-width: 680px) {
    text-align: left;
    flex-wrap: wrap;
    display: flex;
  }

  .active {
    position: relative;
    z-index: 10;
    &::after {
      content: ' ';
      height: 8px;
      width: 100%;
      opacity: 0.32;
      position: absolute;
      left: -3px;
      bottom: 3.5px;
      z-index: -1;
      background: ${({ warning, error }) =>
        warning === "true" ? "#ecb300" :
        error === "true" ? "#e10600" :
        "#00b74f"};
    }
  }
`;

export default MainTitle;
