import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import { getCardDetail } from './actions';

export function* getCardDetailsActionSaga(action) {
  const payload = {
    url: CONSTANT.apiCc.cardDetail(action.payload),
  };

  yield put(getCardDetail.request());
  try {
    const respond = yield call(Api.get, payload);
    yield put(getCardDetail.success(respond));
  } catch (error) {
    yield put(getCardDetail.failure(error));
  }
}

export default function* watchAll() {
  yield all([takeLatest(getCardDetail.TRIGGER, getCardDetailsActionSaga)]);
}
