import React from 'react';
import LinearProgressILD from '@components/LinearProgressILD';

import { useLogicOCRChangeDevice } from './hooks';

import FormGuideSetting from './components/FormGuideSetting';

const OCRChangeDevice = () => {
  const { isIOS, isDisable, onBackScreen } = useLogicOCRChangeDevice();

  return (
    <React.Fragment>
      <LinearProgressILD value={100} />
      <FormGuideSetting
        isIOS={isIOS}
        isDisable={isDisable}
        onBackScreen={onBackScreen}
      />
    </React.Fragment>
  );
};

export default OCRChangeDevice;
