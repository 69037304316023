import get from 'lodash/get';
import { jwtDecode } from "jwt-decode";
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { nextScreen } from '@utils/redirect';
import { screenType } from '@utils/constants';
import * as Api from '@utils/services/api.request';
import * as CONST_GLB from '@utils/services/api.constants';

import { resendOTPEcontractAction, verifyOTPEcontractAction } from './actions';

export function* resendOTPEcontractActionSaga(action) {
  const { phoneNumber } = action.payload;
  const payload = {
    url: CONST_GLB.apiCc.eContractOtp,
    params: {
      phoneNumber,
    },
  };

  yield put(resendOTPEcontractAction.request());
  try {
    const respond = yield call(Api.get, payload);
    yield put(resendOTPEcontractAction.success(respond));
  } catch (error) {
    yield put(resendOTPEcontractAction.failure(error));
  }
}

export function* verifyOTPEcontractActionSaga(action) {
  const { phoneNumber, otpCode } = action.payload;
  const payload = {
    url: CONST_GLB.apiCc.verifyEcontract,
    data: { phoneNumber, otpCode },
  };

  yield put(verifyOTPEcontractAction.request());
  try {
    const respond = yield call(Api.post, payload);

    const { data, meta } = get(respond, 'data') || {};
    if (get(meta, 'code') === 'IL-200' && data) {
      const appId = get(jwtDecode(get(respond, 'data.data.token')), 'appId');

      localStorage.setItem('appId', appId);
      localStorage.setItem('token', `Bearer ${data.token}`);
      if (
        [screenType.WAITING_LOS_DISBURSEMENT, screenType.REJECT].includes(
          get(respond, 'data.data.key'),
        )
      ) {
        nextScreen({ screen: get(respond, 'data.data.key') });
        yield put(verifyOTPEcontractAction.success());
      }
    }
  } catch (error) {
    yield put(verifyOTPEcontractAction.failure(error));
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(resendOTPEcontractAction.TRIGGER, resendOTPEcontractActionSaga),
    takeLatest(verifyOTPEcontractAction.TRIGGER, verifyOTPEcontractActionSaga),
  ]);
}
