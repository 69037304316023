/* eslint-disable no-else-return */
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ROUTE, screenType } from '@utils/constants';
import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as CONSTANT from '@utils/services/api.constants';
import * as Api from '@utils/services/api.request';
import { nextScreen } from '@utils/redirect';

import {
  getFirstDatedebtAction,
  getInfoLoanOfferAction,
  submitLoanOfferAction,
} from './actions';

export function* submitLoanOfferSaga(body) {
  const payload = {
    url: CONSTANT.apiUpl.submitLoanOffer,
    data: get(body, 'payload', {}),
  };

  yield put(submitLoanOfferAction.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200') {
      yield put(submitLoanOfferAction.success());

      switch (get(data, 'screen')) {
        case screenType.COMPLETE_PROFILE: {
          history.push(ROUTE.upl.CompleteProfile);
          break;
        }
        default: {
          toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_01));
          break;
        }
      }
      return;
    }

    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
    yield put(submitLoanOfferAction.failure());
  } catch (error) {
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
    yield put(submitLoanOfferAction.failure());
  }
}

export function* getInfoLoanOfferSaga() {
  const payload = {
    url: CONSTANT.apiUpl.getInfoLoanOffer,
  };

  yield put(getInfoLoanOfferAction.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};
    if (get(meta, 'code') === 'IL-200' && data) {
      if (get(data, 'screen') === screenType.REJECT) {
        nextScreen({ screen: get(data, 'screen') });
        return;
      } else {
        yield put(
          getInfoLoanOfferAction.success({
            data,
            listAccountNumber: (data.accountDisb || []).map(e => ({
              id: e.accountNumber,
              name: e.accountNumber,
            })),
          }),
        );
        return;
      }
    }
    yield put(getInfoLoanOfferAction.failure());
    toast.error(get(meta, 'message') || 'error system');
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getInfoLoanOfferAction.failure(error));
  }
}

export function* getFirstDatedebtSaga(action) {
  const payload = {
    url: CONSTANT.apiUpl.getFirstDatedebt(action.payload),
  };

  yield put(getFirstDatedebtAction.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(
        getFirstDatedebtAction.success({
          data: (data.firstDateDept || []).map(e => ({
            id: e,
            name: e,
          })),
        }),
      );
    } else {
      yield put(getFirstDatedebtAction.failure());
      toast.error(get(meta, 'message') || 'error system');
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getFirstDatedebtAction.failure());
  }
}
export default function* watchAll() {
  yield all([
    takeLatest(submitLoanOfferAction.TRIGGER, submitLoanOfferSaga),
    takeLatest(getInfoLoanOfferAction.TRIGGER, getInfoLoanOfferSaga),
    takeLatest(getFirstDatedebtAction.TRIGGER, getFirstDatedebtSaga),
  ]);
}
