import React from 'react';

import TitleILD from '@components/TitleILD';
import Button from '@components/ButtonILD';
import IOS from '../IOS';
import Android from '../Android';
import Desktop from '../Desktop';
import { FormGuideSettingContainer } from './Styled';

const FormGuideSetting = ({ isIOS, isDisable, onBackScreen }) => (
  <FormGuideSettingContainer>
    <TitleILD warning active={2} text={['Cấp quyền', 'truy cập camera']} />
    {isIOS ? <IOS isIOS={isIOS} /> : <Android isIOS={isIOS} />}
    <Desktop isIOS={isIOS} />

    <Button btnConfirm disabled={isDisable} onClick={onBackScreen}>
      Đã hoàn tất - Tiếp tục
    </Button>
  </FormGuideSettingContainer>
);

export default FormGuideSetting;
