// ".": Thông báo lỗi từ FE
// >= "..": Lỗi từ BE ko trả ra mes

export const MESSAGE_SYSTEM = {
  default: 'error system.', // lỗi chung
  FILE_NOTFOUND: 'Không tìm thấy file hợp đồng.',

  // Không trả ra message thông báo từ BE
  error_01: 'Đã xảy ra lỗi, xin vui lòng thử lại sau..', // res trả về code IL-200 nhưng screen trả về sai so với quy định hoặc không có.
  error_02: 'Cập nhập thông tin không thành công..', // res trả về khác code IL-200.
  error_03: 'Đã xảy ra lỗi, xin vui lòng thử lại sau...', // Lỗi không có appId

  // END Không trả ra message thông báo từ BE
  notFound: 'Không lấy được thông tin',
};
