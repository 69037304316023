import get from 'lodash/get';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import history from '@utils/history';
import * as Api from '@utils/services/api.request';
import { ROUTE, screenType } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { confirmETC } from './actions';

export function* confirmETCSaga(actions) {
  const payload = {
    url: CONSTANT.apiCc.confirmEtcCompleted,
    params: get(actions, 'payload', {}),
  };

  yield put(confirmETC.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      if (get(data, 'screen') === screenType.ETC_COMPLETED) {
        history.push(ROUTE.cc.EtcCompleted);
      }
      yield put(confirmETC.success());
    } else {
      toast.error(get(meta, 'message') || 'error system');
      yield put(confirmETC.failure());
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(confirmETC.failure());
  }
}

export default function* watchAll() {
  yield all([takeLatest(confirmETC.TRIGGER, confirmETCSaga)]);
}
