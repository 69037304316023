import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import history from '@utils/history';
import { ROUTE, screenType } from '@utils/constants';
import { getLocalByKey } from '@utils/helpers';

import { checkResultUpdateInfo } from './actions';

export function* checkResultUpdateInfoSaga() {
  const appId = getLocalByKey('appId');

  if (appId) {
    const payload = {
      url: CONSTANT.apiUpl.infoChecking(appId),
    };

    yield put(checkResultUpdateInfo.request());
    try {
      const respond = yield call(Api.get, payload);
      const { data, meta } = get(respond, 'data') || {};

      if (get(meta, 'code') === 'IL-200') {
        switch (get(data, 'screen')) {
          case screenType.OVERDUE: {
            history.push(ROUTE.upl.Overdue);
            break;
          }
          case screenType.OUT_SIDE_WORKING_HOUR: {
            history.push(ROUTE.upl.OutSideWorkingHour);
            break;
          }
          case screenType.ECONTRACT_LOAN: {
            history.push(ROUTE.upl.EcontractLoan);
            break;
          }
          case screenType.NFC_GUIDE: {
            history.push(ROUTE.common.NFCGuideline);
            return;
          }
          default: {
            break;
          }
        }
      }
      yield put(checkResultUpdateInfo.failure());
    } catch (error) {
      yield put(checkResultUpdateInfo.failure());
    }
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(checkResultUpdateInfo.TRIGGER, checkResultUpdateInfoSaga),
  ]);
}
