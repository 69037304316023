import styled from 'styled-components';

const SettingIOSConatiner = styled.div`
  @padding-left: 30px;
  @line-width: 1px;
  @circle-width: 9px;
  padding-left: @padding-left;
  margin-top: 20px;
  font-size: 14px;
  font-family: SVN-Gilroy;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  @media (min-width: 580px) {
    display: none;
  }

  .popupContent {
    gap: 20px;
    margin: auto;
    display: grid;
    color: #092f51;
    max-width: 546px;
    background: #ffff;
    margin-bottom: 20px;
    .changeBrowser {
      display: grid;
      gap: 20px;
      .step {
        display: flex;
        align-items: center;
        gap: 16px;
        position: relative;
        margin-left: -16px;
        border-radius: 4px;
        padding-right: 16px;
        &:before {
          gap: 10;
          line-height: 20px;
          padding: 6px 9px 6px 9px;
          font-weight: 700;
          font-size: 14px;
          border-radius: 50%;
          background: #104370;
          opacity: 0px;
          color: #fafafa;
          @media (max-width: 392px) {
            width: 38px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .step1 {
        padding-top: 16px;
        p {
          margin: 0;
        }
        &:before {
          content: 'B1';
        }
      }
      .step2 {
        p {
          margin: 0;
        }
        &:before {
          content: 'B2';
        }
      }
      .step3 {
        padding-bottom: 16px;
        &:before {
          content: 'B3';
        }
      }
      .step4 {
        padding-bottom: 18px;
        p {
          margin: 0;
          @media (max-width: 580px) {
            max-width: 261px;
            width: 100%;
          }
        }
        &:before {
          content: 'B4';
        }
      }
      .devider {
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 16px;
          width: 2px;
          height: 70px;
          background: #00b74f;
        }
      }
      .devider1 {
        &:before {
          top: 48px;
          height: 80px;
          @media (min-width: 393px) {
            top: 48px;
          }
          @media (max-width: 393px) {
            top: 53px;
          }
        }
      }
      .devider2 {
        display: grid;
        gap: 8px;
        @media (max-width: 580px) {
          max-width: 278px;
          width: 100%;
        }
        &:before {
          top: 52px;
          height: 90px;
          @media (min-width: 393px) {
            top: 49px;
          }
          @media (max-width: 393px) {
            top: 53px;
          }
        }
      }
      .devider3 {
        @media (max-width: 580px) {
          max-width: 261px;
          width: 100%;
          br {
            display: none;
          }
        }
        p {
          margin: 0;
        }
        &:before {
          top: 56px;
          height: 100%;

          @media (min-width: 393px) {
            top: 55px;
          }
          @media (max-width: 393px) {
            top: 58px;
          }
        }
      }
    }
    .deviderCol {
      height: 8px;
      background: #f2f5f6;
      width: 100%;
    }
    .groupBrowser {
      display: flex;
      align-items: center;
      gap: 40px;
      margin-top: 8px;
      .browser {
        display: flex;
        align-items: center;
        gap: 8px;
        img {
          height: 32px;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          @media (max-width: 580px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
    .copyLink {
      display: flex;
      align-items: normal;
      border: 1px solid #00b74f;
      justify-content: space-between;
      border-radius: 4px;
      height: 40px;
      .content {
        padding-right: 12px;
        display: flex;
        align-items: center;
        .icBrowser {
          height: 20px;
          width: 20px;
          margin-right: 8px;
        }
        .text {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          padding-left: 8px;
          color: #092f51;
        }
      }
      .btnConfirm {
        height: 40px;
        width: 40px;
        margin-right: 23px;
      }
      .btnContinue {
        width: 40px;
        display: flex;
        margin: auto;
        padding: 0;
        margin: 0;
        margin-right: -13px;
      }
    }
  }
`;

export default SettingIOSConatiner;
