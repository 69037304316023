import size from 'lodash/size';
import getLodash from 'lodash/get';
import forEach from 'lodash/forEach';

import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import history from '@utils/history';

import { DOMAIN_CARD, DOMAIN_VAY, ROOT_URI_JARVIS } from './constants';

export const openExternalLink = link => {
  if (link.includes('http')) {
    window.open(link, '_blank');
  } else {
    window.open(`https://${link}`, '_blank');
  }
};

export const useSearchQuery = () => queryString.parse(useLocation().search);

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const isAuthenticated = () => localStorage.getItem('token');

export const formatNumber = n => {
  // format number 1000000 to 1,234,567
  if (String(n).lastIndexOf('.') > -1) {
    const lastIndexOfDot = String(n).lastIndexOf('.');
    const dot = String(n).substring(lastIndexOfDot);
    const value = String(n)
      .substring(0, lastIndexOfDot)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return value + dot;
  }

  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        (event.target.closest('button') &&
          event.target.closest('button').classList.contains('btnToggleMenu'))
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const isIOS = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
  navigator.platform.toUpperCase().indexOf('MAC') >= 0;

export const logout = () => {
  localStorage.clear();
  history.push('/login');
};

export const getLocalByKey = key => localStorage.getItem(key) || null;

export const renderUiByRole = conditionRoles => {
  let result = false;
  const currentRoles = JSON.parse(localStorage.getItem('user_roles'));
  currentRoles.map(role => {
    conditionRoles.map(conditionRole => {
      if (role.toString() === conditionRole.toString()) {
        result = true;
      }
    });
  });
  return result;
};

export const toLocaleString = number => {
  if (number) {
    const cloneNumber = +number;
    return cloneNumber.toLocaleString();
  }
  return '';
};

export const queryParse = string =>
  queryString.parse(string, { arrayFormat: 'comma' });

export const capitalizeFirt = string => {
  const arr = (string || '').split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
};

// ILCMS-451
export const getOfferLimit = value => {
  let limit = Number(value || 0);

  // TH: Các trường hợp còn lại - hiển thị [Số tiền hạn mức được delimiter] + VNĐ
  if (limit <= 100000000 && limit % 1000000 !== 0) {
    return formatNumber(String(limit));
  }

  // TH: Số tiền hạn mức chẵn triệu -> hiển thị dạng [Số tiền hàng triệu]+ Triệu + VNĐ
  if (limit >= 100000000) limit = 100000000;
  return `${parseInt(limit / 1000000)} Triệu`;
};

export const getOfferCard = value => {
  const limit = Number(value || 0);

  // TH: Hạn mức = 1 tỷ: Số tiền hiển thị ->1 Tỷ VNĐ
  if (limit >= 1000000000) return `1 Tỷ`;

  // TH: Hiển thị [Số tiền hạn mức được delimiter] + VNĐ
  if (limit % 1000000 !== 0) {
    return formatNumber(String(limit));
  }

  // TH: Số tiền hạn mức chẵn triệu -> hiển thị dạng [Số tiền hàng triệu]+ Triệu + VNĐ
  return `${parseInt(limit / 1000000)} Triệu`;
};
// END ILCMS-451

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const validateOnlyNumber = number => {
  const reg = new RegExp('^[0-9]+$');
  return reg.test(number);
};

export function nonAccentVietnamese(str) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
}

export function getImageCard(id) {
  return `${ROOT_URI_JARVIS}/card-lp/image/getDataImageById?id=${id}&typeImage=fullsize`;
}

export const autoSuggestionPrice = value => {
  if (value) {
    const string = value.toString();
    const number = parseInt(string.slice(0, 2), 10);
    if (number) {
      return [
        {
          value: (number * 1e6).toLocaleString('vi-Vi'),
          label: (number * 1e6).toLocaleString('vi-Vi'),
        },
        {
          value: (number * 1e7).toLocaleString('vi-Vi'),
          label: (number * 1e7).toLocaleString('vi-Vi'),
        },
      ];
    }
  }
  return [];
};

export const capitalizeTextAll = str => {
  let result = '';
  const arrStr = String(str).split(' ');
  forEach(arrStr, _i => {
    result += `${String(_i)
      .substring(0, 1)
      .toLocaleUpperCase() + String(_i).substring(1)} `;
  });
  return String(result).trim();
};

export const checkSafari = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return isSafari;
};

export const getLinkReEnter = type => {
  if (type) {
    if (type.toUpperCase() === 'CC') {
      return `${DOMAIN_CARD}/re-enter`;
    }
    if (type.toUpperCase() === 'UPL') {
      return `${DOMAIN_VAY}/re-enter`;
    }
  }
};

export const isTwoWord = (str = '') => {
  // Kiểm tra chuỗi phải từ 2 từ trở lên
  const arrStr = String(str).split(' ');
  if (size(arrStr.filter(_i => _i.length)) > 1) return true;
  return false;
};

export const isConsecutiveWhiteSpace = (str = '') => {
  // Kiểm tra 2 khoảng trắng liên tiếp
  str = String(str).trim();
  if (str) {
    let isSpace = false;

    const arrStr = String(str).split(' ');
    forEach(arrStr, text => {
      if (!text) isSpace = true;
    });
    return isSpace;
  }
  return false;
};

export function containsOnlyNumbers(str) {
  return /^\d+$/.test(str);
}

export function translateGenderIntoVietnamese(gender = '') {
  // chuyển giới tính sang tiếng Việt
  if (String(gender) === 'FEMALE') {
    return 'NỮ';
  }
  if (String(gender) === 'MALE') {
    return 'NAM';
  }
  return '';
}

// get params shoppetk
export const useSearchParams = () => {
  const mapSearch = {};
  const urlSearch = window.location.href;

  if (urlSearch && urlSearch.includes('?')) {
    const search = urlSearch.split('?')[1];
    // true
    let params = [];
    if (search && search.includes('&')) {
      params = search.split('&');
    } else {
      params = [search];
    }

    // eslint-disable-next-line no-unused-vars, func-names
    params.forEach(function (item, index, array) {
      // Thực hiện hành động trên mỗi phần tử ở đây
      const s = item.split('=');
      mapSearch[s[0]] = decodeURIComponent(s[1]);
    });
  }

  return mapSearch;
};

export const generateGuid = () =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);

export const copyToClipboard = (text = ' ') => {
  navigator.clipboard.writeText(text);
};

export const isMobile = () => {
  if (window.navigator) {
    // Kiểm tra userAgentData xem thiết bị có phải di động hay không
    if (getLodash(window.navigator, "userAgentData.mobile", false)) {
      return true;
    }

    // Kiểm tra chuỗi userAgent để xác định thiết bị di động
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

    // Kiểm tra các chuỗi trong user agent phổ biến cho điện thoại di động và máy tính bảng
    const mobileDevices = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone|webOS|Mobile|Phone|Tablet/i;

    // Kiểm tra xem thiết bị có phải di động hay không
    if (mobileDevices.test(userAgent)) {
      return true;
    }

    // Kiểm tra nếu kích thước màn hình nhỏ (thường từ 768px trở xuống cho điện thoại)
    if (window.innerWidth <= 768) {
      return true;
    }

    // Kiểm tra kết nối mạng (chỉ là một bổ sung nếu thiết bị có thể là di động)
    if (window.navigator.connection && window.navigator.connection.type === 'cellular') {
      return true;
    }
  }

  return false;
}
