/* eslint-disable no-return-assign */
import history from '@utils/history';
import { DOMAIN_CARD, DOMAIN_VAY, ROUTE, screenType } from '@utils/constants';

export const nextScreen = ({ screen }) => {
  switch (screen) {
    // Router COMMON
    case screenType.WAITING_RESULT_OTP: {
      history.push(ROUTE.common.WaitingCreateAppLos);
      break;
    }
    case screenType.OCR_GUIDE: {
      history.push(ROUTE.common.OCRInstruction);
      break;
    }
    case screenType.OCR_CONFIRM: {
      history.push(ROUTE.common.OCRConfirm);
      break;
    }
    case screenType.LIVE_NESS_GUIDE: {
      history.push(ROUTE.common.Liveness);
      break;
    }
    case screenType.ADDITIONAL_INFO: {
      history.push(ROUTE.common.AdditionalInfo);
      break;
    }
    case screenType.ADDITIONAL_INFO_PA: {
      history.push(ROUTE.common.AdditionalInfoPA);
      break;
    }
    case screenType.ADDITIONAL_WORK_INFO: {
      history.push(ROUTE.common.AdditionalWorkInfo);
      break;
    }
    case screenType.WAITING_LOS_AFTER_OCR: {
      history.push(ROUTE.common.WattingLosAfterOCR);
      break;
    }
    case screenType.WAITING_LOS_RISK_ASSETMENT: {
      history.push(ROUTE.common.WattingLosRiskAssetment);
      break;
    }
    case screenType.WAITING_TP_RESULT: {
      history.push(ROUTE.common.WaitingTPResult);
      break;
    }
    case screenType.VKYC_GUIDELINE: {
      history.push(ROUTE.common.VKYCConnection);
      break;
    }
    case screenType.NFC_GUIDE: {
      history.push(ROUTE.common.NFCGuideline);
      break;
    }
    // END Router COMMON

    // Router CC
    case screenType.INTRODUCTION: {
      history.push(ROUTE.cc.Introduction);
      break;
    }
    case screenType.E_CONTRACT: {
      history.push(ROUTE.cc.Econtract);
      break;
    }
    case screenType.ETC: {
      history.push(ROUTE.cc.EtcConfirm);
      break;
    }
    case screenType.ETC_COMPLETED: {
      history.push(ROUTE.cc.EtcCompleted);
      break;
    }
    case screenType.LIMIT_OFFER_CARD: {
      history.push(ROUTE.cc.CardOffer);
      break;
    }
    case screenType.CARD_RECEIVE_ADDRESS: {
      history.push(ROUTE.cc.DeliveryAddress);
      break;
    }
    case screenType.SECURITY_QUESTION: {
      history.push(ROUTE.cc.SecurityQuestion);
      break;
    }
    case screenType.WAITING_LOS_DISBURSEMENT: {
      history.push(ROUTE.cc.WaitingLosDisbursement);
      break;
    }
    case screenType.WAITING_CREATE_APP_IN_TP: {
      history.push(ROUTE.cc.WaitingProcessGenForm);
      break;
    }
    case screenType.REJECT_CC_NEO: {
      history.push(ROUTE.cc.RejectNEO);
      break;
    }

    // END Router CC

    // Router UPL
    case screenType.OVERDUE: {
      history.push(ROUTE.upl.Overdue);
      break;
    }
    case screenType.LOAN_OFFER: {
      history.push(ROUTE.upl.LoanOffer);
      break;
    }
    case screenType.LOAN_PROFILE: {
      history.push(ROUTE.upl.LoanProfile);
      break;
    }
    case screenType.COMPLETE_PROFILE: {
      history.push(ROUTE.upl.CompleteProfile);
      break;
    }
    case screenType.CHECKING_INFO_UPL: {
      history.push(ROUTE.upl.WaitingCheckingInfo);
      break;
    }
    case screenType.ECONTRACT_LOAN: {
      history.push(ROUTE.upl.EcontractLoan);
      break;
    }
    case screenType.COMPLETED_LOAN: {
      history.push(ROUTE.upl.Completed);
      break;
    }
    case screenType.WAITING_LOS_DISBURSEMENT_LOAN: {
      history.push(ROUTE.upl.WaitingLosDisbursement);
      break;
    }
    case screenType.WAITING_PROCCESING_GEN_ECONTRACT: {
      history.push(ROUTE.upl.WaitingProcessGenForm);
      break;
    }
    // END Router UPL

    // OTHER
    default: {
      if ([screenType.REJECT, screenType.REJECT_FRAUD].includes(screen)) {
        return history.push(ROUTE.common.Reject);
      }

      if (
        [screenType.CARD_PROFILE, screenType.CARD_PROFILE_PA].includes(screen)
      ) {
        return history.push(ROUTE.cc.CardProfile);
      }

      if (
        [
          screenType.REGISTER_COMPLETED,
          screenType.COMPLETED_A,
          screenType.COMPLETED_B,
          screenType.COMPLETED_C,
          screenType.COMPLETED_D,
          screenType.COMPLETED_E,
          screenType.COMPLETED_C_30,
          screenType.COMPLETED_D_30,
          screenType.COMPLETED_E_30,
        ].includes(screen)
      ) {
        return history.push(ROUTE.cc.RegisterCompleted);
      }

      break;
    }
  }
};

export const redirectReEnter = type => {
  if (type) {
    if (type.toUpperCase() === 'CC') {
      window.location.href = `${DOMAIN_CARD}/re-enter`;
    }
    if (type.toUpperCase() === 'UPL') {
      window.location.href = `${DOMAIN_VAY}/re-enter`;
    }
  }
};

export const handleErrorSdkTS = ({ code = '' }) => {
  // https://ekyc.trustingsocial.com/sdks/Web-SDK#possible-error-objects
  // link check error TS result
  if (['no_permission'].includes(code)) {
    return history.push(ROUTE.common.GuidelineSettingCamera);
  }
  if (['not_supported'].includes(code)) {
    return history.push(ROUTE.common.ChangeDevice);
  }
};

export const redirectDOM = href => {
  window.location.href = href;
};
