import { produce } from 'immer';
import get from 'lodash/get';

import { confirmEcontractUpl, getFileEcontractUpl, cancelLoanEcontractUpl } from './actions';

export const initialState = {
  isLoading: false,

  files: {
    econtract: null,
    insurance: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const VKYCConnectionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getFileEcontractUpl.REQUEST: {
        draft.isLoading = true;
        draft.files = {
          econtract: null,
          insurance: null,
        };
        break;
      }
      case getFileEcontractUpl.SUCCESS: {
        draft.isLoading = false;

        draft.files = {
          econtract: get(action, 'payload.econtract', state.files.econtract),
          insurance: get(action, 'payload.insurance', state.files.insurance),
        };
        break;
      }
      case getFileEcontractUpl.FAILURE: {
        draft.isLoading = false;
        break;
      }

      case cancelLoanEcontractUpl.REQUEST: {
        draft.isLoading = true;
        break;
      }
      case cancelLoanEcontractUpl.SUCCESS: {
        draft.isLoading = false;
        break;
      }
      case cancelLoanEcontractUpl.FAILURE: {
        draft.isLoading = false;
        break;
      }

      case confirmEcontractUpl.REQUEST: {
        draft.isLoading = true;
        break;
      }
      case confirmEcontractUpl.SUCCESS: {
        draft.isLoading = false;
        break;
      }
      case confirmEcontractUpl.FAILURE: {
        draft.isLoading = false;
        break;
      }
    }
  });

export default VKYCConnectionReducer;
