
export const ENV = import.meta.env.VITE_ENV;
export const NODE_ENV = import.meta.env.VITE_ENV;
export const ROOT_URI = import.meta.env.VITE_ROOT_URI;
export const ROOT_URI_IL = import.meta.env.VITE_ROOT_URI_IL;
export const DOMAIN_VAY = import.meta.env.VITE_DOMAIN_VAY;
export const DOMAIN_VPB = import.meta.env.VITE_DOMAIN_VPB;
export const DOMAIN_CARD = import.meta.env.VITE_DOMAIN_CARD;
export const ROOT_URI_JARVIS = import.meta.env.VITE_ROOT_URI_JARVIS;
export const KEY_GG_CAPTCHA = import.meta.env.VITE_SITE_KEY_GG_CAPCHA;

export const COUNT_TIMEOUT = 3;
export const API_TIMEOUT = '150000';
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const TOKEN_KEY = 'token';
export const ROUTER_LOGIN = '/login';
export const REFERER_KEY = 'vp-referrer';
export const STREAM_APPID = 'streamAppId';

export const CALL_API_GET_DETAIL = 'CALL_API_GET_DETAIL';
export const TOKEN_KEY_SESSION = '____fp-session-key';
export const KEY_BY_RECORD_FROM_MAIN = '____fp-key-number-oder';
export const REDIRECT_FROM_MAIN_HOST_KEY_SESSION = '____fp-check-redirect-host';

export const SDK_TS = {
  sdkVersion: '5.23.2',
  myCdnUrlPrefix: 'https://vision-vnetwork-cdn.goevo.vn/',
};

export const typeImgOCR = {
  type: 'image/jpeg',
};
export const typePdf = {
  type: 'application/pdf',
};

const prefixCC = '/cc';
const prefixUpl = '/vay';

export const ROUTE = {
  common: {
    Home: '/',
    Otp: '/otp',
    Liveness: '/liveness',
    NFCGuideline: '/nfc-guideline',

    AdditionalInfo: '/additional-info',
    AdditionalInfoPA: '/additional-info-pa',
    AdditionalWorkInfo: '/additional-work-info',

    WaitingTPResult: '/waiting-tp-result',
    WaitingCreateAppLos: '/waiting-result-otp',
    WattingLosAfterOCR: '/waiting-los-after-ocr',
    WattingLosRiskAssetment: '/waiting-risk-assessment',

    Reject: '/reject',
    Register: '/register',
    RejectNEO: '/reject-neo',

    OCRCaptureQR: "/ocr-qr",
    OCRConfirm: '/ocr-confirm',
    OCRCaptureBack: '/ocr-back',
    OCRCaptureFront: '/ocr-front',
    ChangeDevice: '/change-device',
    OCRInstruction: '/ocr-instruction',
    VKYCConnection: '/vkyc-connection',
    GuidelineSettingCamera: '/guideline-setting-camera',
  },
  cc: {
    Econtract: `${prefixCC}/econtract`,
    Introduction: `${prefixCC}/introduction`,
    OtpEcontract: `${prefixCC}/otp-econtract`,
    DeliveryAddress: `${prefixCC}/delivery-address`,
    SecurityQuestion: `${prefixCC}/security-question`,
    RegisterCompleted: `${prefixCC}/register-completed`,
    WaitingLosDisbursement: `${prefixCC}/waiting-los-disbursement`,
    WaitingProcessGenForm: `${prefixCC}/waiting-process-gen-econtract`,

    CardList: `${prefixCC}/card-list`,
    CardOffer: `${prefixCC}/card-offer`,
    CardDetail: `${prefixCC}/card-detail`,
    CardProfile: `${prefixCC}/card-profile`,

    EtcConfirm: `${prefixCC}/etc-confirm`,
    EtcCompleted: `${prefixCC}/etc-completed`,
  },
  upl: {
    Overdue: `${prefixUpl}/overdue`,
    Completed: `${prefixUpl}/completed`,
    LoanOffer: `${prefixUpl}/loan-offer`,
    LoanProfile: `${prefixUpl}/loan-profile`,
    OtpEcontract: `${prefixUpl}/otp-econtract`,
    EcontractLoan: `${prefixUpl}/econtract-loan`,
    CompleteProfile: `${prefixUpl}/complete-profile`,
    WaitingCheckingInfo: `${prefixUpl}/checking-info`,
    OutSideWorkingHour: `${prefixUpl}/outside-working-hours`,
    WaitingLosDisbursement: `${prefixUpl}/waiting-los-disbursement`,
    WaitingProcessGenForm: `${prefixUpl}/waiting-process-gen-econtract`,
  },
};

export const CODE_STATUS = {
  IL200: 'IL-200',
};

export const typeCustomer = {
  NTB: 'NTB',
  ETB_PA: 'ETB_PA',
  ETB_NON_PA: 'ETB_NON_PA',
};
export const LIST_PHONE_HEADER = [
  '032',
  '033',
  '034',
  '035',
  '036',
  '037',
  '038',
  '039',
  '052',
  '056',
  '058',
  '059',
  '070',
  '076',
  '077',
  '078',
  '079',
  '081',
  '082',
  '083',
  '084',
  '085',
  '086',
  '088',
  '089',
];

export const screenType = {
  ETC: 'ETC',
  OTP: 'OTP',
  REJECT: 'REJECT',
  REGISTER: 'REGISTER',
  OCR_BACK: 'OCR_BACK',
  OCR_FRONT: 'OCR_FRONT',
  OCR_GUIDE: 'OCR_GUIDE',
  WAITING_LOS: 'WAITING_LOS',
  OCR_CONFIRM: 'OCR_CONFIRM',
  SECOND_CARD: 'SECOND_CARD',
  INTRODUCTION: 'INTRODUCTION',
  CARD_PROFILE: 'CARD_PROFILE',
  REJECT_FRAUD: 'REJECT_FRAUD',
  REDIRECT_OCR: 'REDIRECT_OCR',
  ETC_COMPLETED: 'ETC_COMPLETED',
  NO_LIMIT_FLOW: 'NO_LIMIT_FLOW',
  CARD_PROFILE_PA: 'CARD_PROFILE_PA',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO',
  LIMIT_OFFER_CARD: 'LIMIT_OFFER_CARD',
  SECURITY_QUESTION: 'SECURITY_QUESTION',
  ADDITIONAL_INFO_PA: 'ADDITIONAL_INFO_PA',
  REGISTER_COMPLETED: 'REGISTER_COMPLETED',
  WAITING_RESULT_OTP: 'WAITING_RESULT_OTP',
  CARD_RECEIVE_ADDRESS: 'CARD_RECEIVE_ADDRESS',
  ADDITIONAL_WORK_INFO: 'ADDITIONAL_WORK_INFO',
  WAITING_LOS_AFTER_OCR: 'WAITING_LOS_AFTER_OCR',
  WAITING_LOS_RISK_ASSETMENT: 'WAITING_LOS_RISK_ASSETMENT',
  RE_LOGIN: 'RE_LOGIN',
  WAITING_CREATE_APP_IN_TP: 'WAITING_CREATE_APP_IN_TP',
  E_CONTRACT: 'E_CONTRACT',
  LIVE_NESS_GUIDE: 'LIVE_NESS_GUIDE',
  VKYC_GUIDELINE: 'VKYC_GUIDELINE',
  WAITING_TP_RESULT: 'WAITING_TP_RESULT',
  WAITING_LOS_DISBURSEMENT: 'WAITING_LOS_DISBURSEMENT',
  COMPLETED_A: 'COMPLETED_A',
  COMPLETED_B: 'COMPLETED_B',
  COMPLETED_C: 'COMPLETED_C',
  COMPLETED_D: 'COMPLETED_D',
  COMPLETED_E: 'COMPLETED_E',
  COMPLETED_C_30: 'COMPLETED_C_30',
  COMPLETED_D_30: 'COMPLETED_D_30',
  COMPLETED_E_30: 'COMPLETED_E_30',
  REJECT_CC_NEO: 'REJECT_CC_NEO',
  NFC_GUIDE: 'NFC_GUIDE',
  CAPTURE_QR: 'CAPTURE_QR',

  // UPL
  OVERDUE: 'OVERDUE',
  LOAN_OFFER: 'LOAN_OFFER',
  LOAN_PROFILE: 'LOAN_PROFILE',
  COMPLETED_LOAN: 'COMPLETED_LOAN',
  ECONTRACT_LOAN: 'ECONTRACT_LOAN',
  COMPLETE_PROFILE: 'COMPLETE_PROFILE',
  CHECKING_INFO_UPL: 'CHECKING_INFO_UPL',
  VERIFY_OTP_ECONTRACT: 'VERIFY_OTP_ECONTRACT',
  OUT_SIDE_WORKING_HOUR: 'OUT_SIDE_WORKING_HOUR',
  WAITING_LOS_DISBURSEMENT_LOAN: 'WAITING_LOS_DISBURSEMENT_LOAN',
  WAITING_PROCCESING_GEN_ECONTRACT: 'WAITING_PROCCESING_GEN_ECONTRACT',
};

export const stepType = {
  COMPLETED_A: 'COMPLETED_A',
  COMPLETED_B: 'COMPLETED_B',
  COMPLETED_C: 'COMPLETED_C',
  COMPLETED_D: 'COMPLETED_D',
  COMPLETED_E: 'COMPLETED_E',
  COMPLETED_C_WAITING: 'COMPLETED_C_WAITING',
  COMPLETED_D_WAITING: 'COMPLETED_D_WAITING',
};

export const TYPE_MODAL_CHOOSE_ADDRESS = {
  CHOOSE_TOWER: 'CHOOSE_TOWER',
  CHOOSE_PROVINCE: 'CHOOSE_PROVINCE',
  CHOOSE_DISTRICT: 'CHOOSE_DISTRICT',
  INPUT_ADDRESS_DETAIL: 'INPUT_ADDRESS_DETAIL',
};

export const PRODUCT_TYPE = {
  CC: 'CC',
  UPL: 'UPL',
};
