import getLd from 'lodash/get';
import { produce } from 'immer';

import { getLinkWS6Upl, checkOutsideWorkHourUpl } from './actions';

export const initialState = {
  layout: '',
  titleModal: '',

  isRetryLink: false,
  isShowLayout: false,
  isOverLimitLink: false,
  isConnectingLink: false,
};

/* eslint-disable default-case, no-param-reassign */
const VKYCConnectionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getLinkWS6Upl.REQUEST: {
        draft.isRetryLink = false;
        draft.isOverLimitLink = false;

        draft.titleModal = getLd(action, 'payload.title', '');
        draft.isConnectingLink = getLd(action, 'payload.isConnectingLink', '');
        break;
      }
      case getLinkWS6Upl.SUCCESS: {
        draft.isRetryLink = false;
        draft.isOverLimitLink = false;
        draft.isConnectingLink = true;
        break;
      }
      case getLinkWS6Upl.FAILURE: {
        draft.isConnectingLink = false;

        draft.titleModal = getLd(action, 'payload.title', '');
        draft.isRetryLink = getLd(action, 'payload.isRetryLink', false);
        draft.isOverLimitLink = getLd(action, 'payload.isOverLimitLink', false);
        break;
      }

      case checkOutsideWorkHourUpl.REQUEST: {
        draft.layout = '';
        break;
      }
      case checkOutsideWorkHourUpl.SUCCESS: {
        draft.layout = getLd(action, 'payload.layout', false);
        break;
      }
      case checkOutsideWorkHourUpl.FAILURE: {
        draft.layout = '';
        break;
      }
    }
  });

export default VKYCConnectionReducer;
