import getLd from 'lodash/get';
import axios from 'axios';
import { generateGuid } from '@utils/helpers';
import { ROOT_URI, ROOT_URI_JARVIS, API_TIMEOUT } from '@utils/constants';

const channelID = 5;
const instance = axios.create({
  baseURL: ROOT_URI,
  timeout: API_TIMEOUT,
});

const instanceJarvis = axios.create({
  baseURL: ROOT_URI_JARVIS,
  timeout: API_TIMEOUT,
});
const sendRequest = ({ url, method, params, data, responseType }) =>
  instance({
    url,
    method,
    params: {
      ...params,
      channelID,
    },
    data,
    headers: {
      "x-request-header-auto": generateGuid(),
      Authorization: localStorage.getItem('token') || '',
    },
    responseType,
  })
    .then(response => {
      if (!response.data.error || response.data.error === 0) {
        return handleSuccess(response);
      }
      return handleError(response);
    })
    .catch(error => handleError(error));

const sendRequestJarvis = ({ url, method, params, data }) =>
  instanceJarvis({
    url,
    method,
    params: {
      ...params,
      channelID,
    },
    data,
    // headers: {
    //   Authorization: localStorage.getItem('token') || '',
    // },
  })
    .then(response => {
      if (!response.data.error || response.data.error === 0) {
        return handleSuccess(response);
      }
      return handleError(response);
    })
    .catch(error => handleError(error));
export const getJarvis = ({ url, params }) =>
  sendRequestJarvis({ url, params, method: 'GET' });
export const postJarvis = ({ url, params, data }) =>
  sendRequestJarvis({ url, params, data, method: 'POST' });

export const get = ({ url, params }) =>
  sendRequest({ url, params, method: 'GET' });

export const post = ({ url, params, data }) =>
  sendRequest({ url, params, data, method: 'POST' });

export const put = ({ url, params, data }) =>
  sendRequest({ url, params, data, method: 'PUT' });

export const deleteData = ({ url, params, data }) =>
  sendRequest({ url, params, data, method: 'DELETE' });

export const getFile = ({ url, params, notAuth, responseType }) =>
  sendRequest({ url, params, method: 'GET', notAuth, responseType });

const handleSuccess = respond => Promise.resolve(respond);

const handleError = error => {
  const errorSystem = getLd(error, 'response.data.meta.fe_code', null);
  if (errorSystem) {
    document.getElementById('codeError').innerHTML = `Err: ${errorSystem}`;
  }

  if (error.response) {
    return Promise.reject(error && error.response && error.response.data);
  }

  return Promise.reject(error);
};

const sendDownloadRequest = ({
  url,
  method,
  params,
  data,
  apiName = '',
  headers = {},
}) =>
  instance({
    url,
    method,
    params,
    data,
    responseType: 'blob',
    headers: {
      ...headers,
      Authorization:
        process.browser && localStorage.getItem('token')
          ? `${localStorage.getItem('token')}`
          : '',
      'Access-Control-Allow-Origin': '*',
    },
    // mode: 'no-cors',
  })
    .then(response => {
      const url1 = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url1;
      const fileName = `Hop-dong-${response.config.params.name}.pdf`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => handleError(error, apiName));

export const download = ({
  url,
  params = {},
  apiName,
  isGetHeader,
  showNoti = false,
}) =>
  sendDownloadRequest({
    url,
    params,
    method: 'GET',
    apiName,
    isGetHeader,
    showNoti,
  });
