import { produce } from 'immer';
import getLd from 'lodash/get';

import { confirmOcr, getOptInputIdPrefix } from './actions';

export const initialState = {
  loading: false,
  prefixOpt: [],
};

/* eslint-disable default-case, no-param-reassign */
const CardProfileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getOptInputIdPrefix.REQUEST: {
        draft.loading = true;
        break;
      }
      case getOptInputIdPrefix.SUCCESS: {
        draft.loading = false;
        draft.prefixOpt = getLd(action, 'payload.items', []);
        break;
      }
      case getOptInputIdPrefix.FAILURE: {
        draft.loading = false;
        break;
      }

      case confirmOcr.REQUEST: {
        draft.loading = true;
        break;
      }
      case confirmOcr.SUCCESS: {
        draft.loading = false;
        break;
      }
      case confirmOcr.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });

export default CardProfileReducer;
