import get from 'lodash/get';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { ROUTE, screenType } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { checkImageOCRBack } from './actions';

export function* checkImageOCRBackSaga(action) {
  const payload = {
    url: CONSTANT.apiCommon.ocrScanBack,
    data: get(action, 'payload'),
  };
  yield put(checkImageOCRBack.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {

      switch (data.key) {
        case screenType.REJECT: {
          yield put(checkImageOCRBack.success());
          history.push(`${ROUTE.common.Reject}?id=reject_otp`);
          break;
        }
        case screenType.OCR_CONFIRM: {
          yield put(checkImageOCRBack.success());
          history.push(ROUTE.common.OCRConfirm);
          break;
        }
        case screenType.CAPTURE_QR: {
          yield put(checkImageOCRBack.fulfill());
          history.push(ROUTE.common.OCRCaptureQR);
          break;
        }
        case "RETRY": {
          yield put(checkImageOCRBack.fulfill());
          toast.warning(get(data, 'message', ''));
          history.push(ROUTE.common.OCRCaptureFront);
          break;
        }
        case "RETRY_BACK": {
          yield put(
            checkImageOCRBack.failure({
              message: get(
                data,
                'message',
                'Ảnh của bạn không đủ chất lượng. Vui lòng bấm “Chụp lại” để thử lại.',
              ),
            }),
          );
          break;
        }
        default: {
          checkImageOCRBack.failure({
            message: get(data, "message", MESSAGE_SYSTEM.error_01)
          })
          break;
        }
      }
      return;
    }

    toast.error(get(data, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
    yield put(
      checkImageOCRBack.failure({
        message: get(error, 'meta.message', MESSAGE_SYSTEM.default),
      }),
    );
  }
}

export default function* watchAll() {
  yield all([takeLatest(checkImageOCRBack.TRIGGER, checkImageOCRBackSaga)]);
}
