/* eslint-disable prettier/prettier */
export const apiCommon = {
  countries: 'countries',
  ward: 'core/v2/public/wards/get-all',
  liveness: 'card/v2/public/live-ness',
  getConfig: 'core/v2/public/config/all',
  getDistrict: 'core/v2/public/district',
  getProvince: 'core/v2/public/province',
  nfcQrCode: 'card/v2/public/nfc/qr-code',
  selections: 'core/v2/public/selections',
  ocrScanQR: 'card/v2/public/ocr/scan-qr',
  ocrConfirm: 'card/v2/public/ocr/confirm',
  otpRequest: 'gateway/v2/public/otp/request',
  ocrScanBack: 'card/v2/public/ocr/scan-back',
  ocrSetting: 'gateway/v2/public/ocr/setting',
  getLinkWs6: 'card/v2/public/tpc/ws6/get-link',
  ocrScanFront: 'card/v2/public/ocr/scan-front',
  otpVerify: 'card/v2/public/applications/initApp',
  settingSDK: 'gateway/v2/public/live-ness/setting',
  addPaInfo: 'card/v2/public/customers/add-pa-info',
  addNonPaInfo: 'card/v2/public/customers/add-non-pa-info',
  addNonPaWork: 'card/v2/public/customers/add-non-pa-work',
  getWorkingTime: 'core/v2/public/working-time/get-workingTime',
  validationTimeWork: 'core/v2/public/working-time/validation-time-work',
  // FUNC
  applicationDetail: appId => `card/v2/public/applications/${appId}`,
  getFileEContract: appId => `gateway/v2/public/storage-file/download-contract/${appId}`,
};

export const apiCc = {
  remind: 'card/v2/public/remind',
  benefits: 'card/v2/public/benefits',
  listCard: 'card/v2/public/cards-offers',
  eContractOtp: 'card/v2/public/otp/e-contract',
  deliveryBranch: 'core/v2/public/delivery-branch',
  waitingTpc: 'card/v2/public/applications/waiting-tpc',
  addSecInfo: 'card/v2/public/applications/add-sec-info',
  cardConfigRule: 'card/v2/public/card-config/rule-card',
  checkWtLosDisb: 'card/v2/public/applications/redirect',
  bonusService: 'card/v2/public/applications/bonus_service',
  confirmEtcCompleted: 'card/v2/public/applications/etc_completed',
  verifyEcontract: 'card/v2/public/applications/e-contract-confirm',
  findByProvinceCode: 'core/v2/public/delivery-branch/find-by-province-code',
  deliveryBranchLiness: 'card/v2/public/applications/delivery_branch_liness',
  getStatusDisbursement: 'card/v2/public/applications/get-status-disbursement',
  deliveryBranchPhysical: 'card/v2/public/applications/delivery_branch_physical',
  getTemplateByCardTypeIdAndFlowId: 'card-lp/template/getTemplateByCardTypeIdAndFlowId',
  // FUNC
  cardDetail: cardId => `card/v2/public/cards-offers/${cardId}`,
  checkCa: appId => `card/v2/public/cards-offers/check_ca/${appId}`,
};

export const apiUpl = {
  confirmApproved: 'upl/v2/public/applications/update',
  eContractOtp: 'upl/v2/public/applications/e-contract-otp',
  verifyEcontract: 'upl/v2/public/applications/verify-e-contract',
  cancelEcontractLoan: 'upl/v2/public/applications/cancel-econtract-loan',
  getInfoLoanOffer: '/upl/v2/public/applications/get-info-loan-offer',
  submitCompleteProfile:'/upl/v2/public/applications/complete-profile',
  submitLoanOffer: '/upl/v2/public/applications/update-info-loan-offer',
  proccesingGenEcontract: 'upl/v2/public/applications/waiting-proccesing-gen-econtract',
  // FUNC
  infoChecking: appId => `upl/v2/public/info/check/${appId}`,
  getFirstDatedebt: deptPayDatePeriod => `/upl/v2/public/applications/get-first-date-debt/${deptPayDatePeriod}`,
};
