/*
 *
 * Lady Mastercard reducer
 *
 */
import { produce } from 'immer';

import get from 'lodash/get';
import { resendOTPEcontractAction, verifyOTPEcontractAction } from './actions';

export const initialState = {
  loading: false,
  msgError: null,
  flagError: false,
  codeErr: null,
  keyRedirect: null,
  appIdOtp: null,
};

/* eslint-disable default-case, no-param-reassign */
const OtpReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case resendOTPEcontractAction.REQUEST: {
        draft.loading = true;
        draft.flagError = false;
        draft.msgError = null;
        draft.codeErr = null;

        break;
      }
      case resendOTPEcontractAction.SUCCESS: {
        draft.loading = false;
        draft.flagError = false;
        draft.msgError = null;
        draft.codeErr = null;
        break;
      }
      case resendOTPEcontractAction.FAILURE: {
        draft.loading = false;
        draft.msgError = action.payload.message;
        draft.flagError = true;
        draft.codeErr = action.payload.error;
        break;
      }
      case verifyOTPEcontractAction.REQUEST: {
        draft.loading = true;
        draft.keyRedirect = null;
        draft.flagError = false;
        draft.msgError = null;
        draft.codeErr = null;
        break;
      }
      case verifyOTPEcontractAction.SUCCESS: {
        draft.loading = true;
        draft.flagError = false;
        break;
      }
      case verifyOTPEcontractAction.FAILURE: {
        draft.loading = false;
        draft.msgError = get(action, 'payload.meta.message') || null;
        draft.codeErr = action.payload.meta.code;
        draft.keyRedirect = null;
        draft.flagError = true;
        break;
      }
    }
  });
export default OtpReducer;
