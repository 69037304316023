export const key = 'video_call_cc';
export const LAYOUT = {
    INTRODUCTION: "INTRODUCTION",
    OUTSIDE_WORK_HOUR: "OUTSIDE_WORK_HOUR"
}

export const GET_CUT_OFFTIME = 'app/videoCall/GET_CUT_OFFTIME';
export const GET_LINK_THINH_PHAT = 'app/videoCall/GET_LINK_THINH_PHAT';

export const VIDEO_CALL_TPC = 'VIDEO_CALL_TPC';
export const RETRY = 'RETRY';

export const TITLE_WORKING = ['Hướng dẫn thực hiện', 'Video Call'];
export const TITLE_OVETIME = ['Ngoài thời gian', 'làm việc'];

export const TITLE_LOADING = 'Hệ thống đang kết nối với tổng đài viên';
export const TITLE_RETRY = 'Hiện tất cả các tổng đài viên đều bận';
