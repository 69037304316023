import { produce } from 'immer';
import get from 'lodash/get';

import {
  getStatusWaitingDisb,
  checkWattingDetailDisb,
  checkWattingLosDisbursement,
} from './actions';

export const initialState = {
  loading: false,
  flagCallBackInfor: false,
  detail: {},
};

/* eslint-disable default-case, no-param-reassign */
const WaitingLosDisbReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case checkWattingLosDisbursement.REQUEST: {
        draft.loading = true;
        draft.flagCallBackInfor = false;
        break;
      }
      case checkWattingLosDisbursement.SUCCESS: {
        draft.loading = false;
        draft.flagCallBackInfor = true;
        break;
      }
      case checkWattingLosDisbursement.FAILURE: {
        draft.loading = false;
        draft.flagCallBackInfor = true;
        break;
      }
      case checkWattingDetailDisb.REQUEST: {
        draft.loading = true;
        break;
      }
      case checkWattingDetailDisb.SUCCESS: {
        draft.loading = false;
        draft.detail = get(action, 'payload.data.data') || {};
        break;
      }
      case checkWattingDetailDisb.FAILURE: {
        draft.loading = false;
        break;
      }
      case getStatusWaitingDisb.REQUEST: {
        draft.loading = true;
        draft.flagCallBackInfor = false;
        break;
      }
      case getStatusWaitingDisb.SUCCESS: {
        draft.loading = false;
        draft.flagCallBackInfor = false;
        break;
      }
      case getStatusWaitingDisb.FAILURE: {
        draft.loading = false;
        draft.flagCallBackInfor = false;
        break;
      }
    }
  });
export default WaitingLosDisbReducer;
