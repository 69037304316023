import MainTitle from './Styled';

export default function TitleILD({
  text = [],
  error = false,
  active = null,
  warning = false,
}) {
  // Truyền warning chỉ khi warning === true
  return (
    <MainTitle
      warning={warning ? "true" : undefined}  // Chuyển thành chuỗi hoặc undefined
      error={error ? "true" : undefined}      // Làm tương tự với error
      className="titlePage"
    >
      {text.map((value, indText) => (
        <span
          key={`text-${indText}`} // Đơn giản hóa key
          className={active === indText + 1 ? 'active' : ''}
        >
          {value}&nbsp;
        </span>
      ))}
    </MainTitle>
  );
}
