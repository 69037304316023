import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { DOMAIN_CARD } from '@utils/constants';
import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import { getRemindAction, submitRemindAction } from './actions';

export function* getRemindSaga() {
  const payload = {
    url: CONSTANT.apiCommon.selections,
    params: { category: 'REMIND' },
  };

  yield put(getRemindAction.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(
        getRemindAction.success({ listRemind: isArray(data) ? data : [] }),
      );
    } else {
      yield put(getRemindAction.failure());
      toast.error(get(meta, 'message') || 'error system');
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getRemindAction.failure());
  }
}

export function* submitRemindSaga(action) {
  const payload = {
    url: CONSTANT.apiCc.remind,
    data: get(action, 'payload'),
  };

  yield put(submitRemindAction.request());
  try {
    const respond = yield call(Api.post, payload);
    const { meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200') {
      window.location.href = `${DOMAIN_CARD}/re-enter`;
    } else {
      toast.error(get(meta, 'message') || 'error system');
    }
    yield put(submitRemindAction.success());
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(submitRemindAction.failure());
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(getRemindAction.TRIGGER, getRemindSaga),
    takeLatest(submitRemindAction.TRIGGER, submitRemindSaga),
  ]);
}
