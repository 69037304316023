/* eslint-disable prettier/prettier */
import { PRODUCT_TYPE } from '@utils/constants';

export const key = 'upl_loan_offer';

export const listMonthlyRepaymentDate = [
  { name: '5', id: '5' },
  { name: '10', id: '10' },
  { name: '15', id: '15' },
];

export const SUBMIT_LOAN_OFFER = `app/${PRODUCT_TYPE.UPL}/${key}/SUBMIT_LOAN_OFFER`;
export const GET_FIRST_DATE_DEBT = `app/${PRODUCT_TYPE.UPL}/${key}/GET_FIRST_DATE_DEBT`;
export const GET_INFO_LOAN_OFFER = `app/${PRODUCT_TYPE.UPL}/${key}/GET_INFO_LOAN_OFFER`;
