import get from "lodash/get";
import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";

import history from "@utils/history";
import { MESSAGE_SYSTEM } from "@utils/message";
import * as Api from "@utils/services/api.request";
import { ROUTE, screenType } from "@utils/constants";
import * as CONSTANT from "@utils/services/api.constants";

import { checkImageOCRQRAction } from "./actions";

export function* checkImageOCRQRSaga(action) {
  const payload = {
    url: CONSTANT.apiCommon.ocrScanQR,
    data: get(action, 'payload'),
  };

  try {
    yield put(checkImageOCRQRAction.request());
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      switch (data.key) {
        case screenType.REJECT: {
          yield put(checkImageOCRQRAction.success());
          history.push(`${ROUTE.common.Reject}?id=reject_otp`);
          break;
        }
        case screenType.OCR_CONFIRM: {
          yield put(checkImageOCRQRAction.success());
          history.push(ROUTE.common.OCRConfirm);
          break;
        }
        case "RETRY": {
          yield put(checkImageOCRQRAction.fulfill());
          history.push(ROUTE.common.OCRCaptureFront);
          break;
        }
        default: {
          checkImageOCRQRAction.failure({
            message: get(data, "message", MESSAGE_SYSTEM.error_01)
          })
          break;
        }
      }
      return;
    }

    toast.error(get(data, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
    yield put(
      checkImageOCRQRAction.failure({
        message: get(error, 'meta.message', MESSAGE_SYSTEM.default),
      }),
    );
  }
}

export default function* watchAll() {
  yield all([takeLatest(checkImageOCRQRAction.TRIGGER, checkImageOCRQRSaga)]);
}
