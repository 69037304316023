import { createRoutine } from 'redux-saga-routines';
import {
  DOWNLOAD_ECONTRACT_ACTION,
  GET_FILE_PDF_ACTION,
  SEND_OTP_ECONTRACT_ACTION,
} from './constants';

export const sendOTPAction = createRoutine(SEND_OTP_ECONTRACT_ACTION);
export const downloadEcontractAction = createRoutine(DOWNLOAD_ECONTRACT_ACTION);
export const getFilePDFAction = createRoutine(GET_FILE_PDF_ACTION);
