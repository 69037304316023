import { createRoutine } from 'redux-saga-routines';

import {
  GET_FILE_ECONTRACT_UPL,
  CONFIRM_ECONTRACT_UPL,
  CANCEL_LOAN_ECONTRACT_UPL,
} from './constants';

export const getFileEcontractUpl = createRoutine(GET_FILE_ECONTRACT_UPL);
export const confirmEcontractUpl = createRoutine(CONFIRM_ECONTRACT_UPL);
export const cancelLoanEcontractUpl = createRoutine(CANCEL_LOAN_ECONTRACT_UPL);
