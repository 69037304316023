import { createRoutine } from 'redux-saga-routines';
import {
  RESEND_OTP_ECONTRACT_ACTION,
  VERIFY_OTP_ECONTRACT_ACTION,
} from './constants';

export const resendOTPEcontractAction = createRoutine(
  RESEND_OTP_ECONTRACT_ACTION,
);
export const verifyOTPEcontractAction = createRoutine(
  VERIFY_OTP_ECONTRACT_ACTION,
);
