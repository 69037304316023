import { produce } from "immer";
import get from "lodash/get";

import { getInfoLimitCard, getPriorityCardAction } from "./actions";

export const initialState = {
  limitCardOffer: null,
  listPriorityCard: [],
  loadingCardOffer: false,
  loadingLimitCard: false
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  // eslint-disable-next-line consistent-return
  produce(state, draft => {
    switch (action.type) {
      case getPriorityCardAction.REQUEST:
        draft.loadingCardOffer = true;
        break;
      case getPriorityCardAction.SUCCESS:
        draft.loadingCardOffer = false;
        draft.listPriorityCard = get(action, "payload.data.data");
        break;
      case getPriorityCardAction.FAILURE:
        draft.loadingCardOffer = false;
        break;
      case getInfoLimitCard.REQUEST:
        draft.loadingLimitCard = true;
        break;
      case getInfoLimitCard.SUCCESS:
        draft.loadingLimitCard = false;
        draft.limitCardOffer =
          get(action, "payload.appDetail.final_approved") || 0;
        break;
      case getInfoLimitCard.FAILURE:
        draft.loadingLimitCard = false;
        draft.limitCardOffer = initialState.limitCardOffer;
        break;
    }
  });
export default appReducer;
