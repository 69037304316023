/* eslint-disable prettier/prettier */
import { select, all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { ROUTE, screenType } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import {
  getTowers,
  getCheckCa,
  getProvinces,
  getDistricts,
  getCardDesign,
  getWorkTimeApp,
  getCountryAction,
  getSettingSdkOCR,
  updateApplication,
  closeModalCheckCa,
  getInfoCustomerDetail,
  createNiceDigitalAccountAction,
} from './actions';

export function* getInfoCustomerDetailSaga(action) {
  const appId = get(action, 'payload.appId') || localStorage.getItem('appId');

  if (appId) {
    const payload = {
      url: CONSTANT.apiCommon.applicationDetail(appId),
      params: null,
    };

    yield put(getInfoCustomerDetail.request());
    try {
      const respond = yield call(Api.get, payload);
      const { data, meta } = get(respond, 'data') || {};

      if (get(meta, 'code') === 'IL-200' && data) {
        yield put(getInfoCustomerDetail.success({ appDetail: data || {} }));
        return;
      }

      yield put(getInfoCustomerDetail.failure());
      toast.error(get(data, 'message', MESSAGE_SYSTEM.error_02));
    } catch (error) {
      toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
      yield put(getInfoCustomerDetail.failure());
    }
  }
}

export function* getCountryActionSaga() {
  const payload = {
    url: `/countries`,
  };

  yield put(getCountryAction.request());
  try {
    const respond = yield call(Api.getJarvis, payload);
    yield put(getCountryAction.success(respond));
  } catch (error) {
    yield put(getCountryAction.failure(error));
  }
}

export function* closeModalCheckCaSaga() {
  yield put(closeModalCheckCa.request());
}
export function* getCheckCaActionSaga() {
  yield put(getCheckCa.request());
}
export function* updateApplicationSaga(action) {
  const payload = {
    url: CONSTANT.apiCommon.applicationDetail(get(action, 'payload.appId')),
    data: get(action, 'payload') || null,
  };

  yield put(updateApplication.request());
  try {
    const respond = yield call(Api.put, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(updateApplication.success());
      if (get(data, 'screen') === 'OCR_GUIDE') {
        return history.push(ROUTE.common.OCRInstruction);
      }
      if (get(data, 'screen') === 'CARD_PROFILE') {
        return history.push(ROUTE.cc.CardProfile);
      }
    } else {
      toast.error(get(meta, 'message') || 'error system');
      yield put(updateApplication.failure());
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(updateApplication.failure());
  }
}

// export function* getCheckCaActionSaga() {
//   const appId = localStorage.getItem('appId');
//   if (appId) {
//     const payload = {
//       url: CONSTANT.apiCc.checkCa(appId),
//     };

//     yield put(getCheckCa.request());
//     try {
//       const respond = yield call(Api.get, payload);
//       yield put(getCheckCa.success(respond));
//     } catch (error) {
//       toast.error(get(error, 'meta.message') || 'error system');
//       yield put(getCheckCa.failure(error));
//     }
//   }
// }
export function* getCardDesignActionSaga(action) {
  const payload = {
    url: `/card-lp/template/getTemplateByCardTypeIdAndFlowId?cardTypeId=${get(
      action,
      'payload',
    )}`,
  };

  yield put(getCardDesign.request());
  try {
    const respond = yield call(Api.getJarvis, payload);
    // bo card design default theo ticket ILCMS-272
    const newArrCard = get(respond, 'data.lstOutput').filter(
      e => e.templateCode !== 'Default',
    );
    yield put(
      getCardDesign.success({
        ...respond,
        data: { ...respond.data, lstOutput: newArrCard },
      }),
    );
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getCardDesign.failure(error));
  }
}
export function* createNiceDigitalAccountSaga(action) {
  const payload = {
    url: CONSTANT.apiCc.bonusService,
    data: get(action, 'payload') || null,
  };

  yield put(createNiceDigitalAccountAction.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (
      get(meta, 'code') === 'IL-200' &&
      get(data, 'screen') === screenType.SECURITY_QUESTION
    ) {
      history.push(ROUTE.cc.SecurityQuestion);
    } else {
      toast.error(get(meta, 'message') || 'error system');
      yield put(createNiceDigitalAccountAction.failure());
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(createNiceDigitalAccountAction.failure());
  }
}
// golive 01-03-2024
export function* getTowersSaga() {
  const payload = {
    url: CONSTANT.apiCommon.ward,
  };

  yield put(getTowers.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(getTowers.success({ listTower: isArray(data) ? data : [] }));
    } else {
      yield put(getTowers.failure());
      toast.error(get(meta, 'message') || 'error system');
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getTowers.failure());
  }
}

export function* getProvincesSaga() {
  const payload = {
    url: CONSTANT.apiCommon.getProvince,
    params: null,
    data: null,
  };

  yield put(getProvinces.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(
        getProvinces.success({ listProvince: isArray(data) ? data : [] }),
      );
    } else {
      yield put(getProvinces.failure());
      toast.error(get(meta, 'message') || 'error system');
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getProvinces.failure());
  }
}

export function* getDistrictsSaga() {
  const payload = {
    url: CONSTANT.apiCommon.getDistrict,
    params: null,
    data: null,
  };

  yield put(getDistricts.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(
        getDistricts.success({ listDistrict: isArray(data) ? data : [] }),
      );
    } else {
      yield put(getDistricts.failure());
      toast.error(get(meta, 'message') || 'error system');
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getDistricts.failure());
  }
}

export function* getSettingSdkOCRSaga() {
  const payload = {
    url: CONSTANT.apiCommon.ocrSetting,
    params: null,
    data: null,
  };

  yield put(getSettingSdkOCR.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(respond, 'status') === 200 && data) {
      return yield put(getSettingSdkOCR.success({ data }));
    }

    toast.error(get(meta, 'message') || 'error system');
    yield put(getSettingSdkOCR.failure());
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getDistricts.failure());
  }
}

export function* getWorkTimeAppSaga(action) {
  const productType = get(action, 'payload.productType', null);
  const payload = {
    url: CONSTANT.apiCommon.getWorkingTime,
    params: { productType },
  };

  yield put(getWorkTimeApp.request());
  let workTime = yield select(state => get(state, 'global.workTime', {}));

  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      return yield put(getWorkTimeApp.success({
        ...workTime,
        ...data
      }));
    }

    yield put(getWorkTimeApp.failure());
    toast.error(get(data, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
    yield put(getWorkTimeApp.failure());
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(getTowers.TRIGGER, getTowersSaga),
    takeLatest(getDistricts.TRIGGER, getDistrictsSaga),
    takeLatest(getProvinces.TRIGGER, getProvincesSaga),
    takeLatest(getCheckCa.TRIGGER, getCheckCaActionSaga),
    takeLatest(getCardDesign.TRIGGER, getCardDesignActionSaga),
    takeLatest(getCountryAction.TRIGGER, getCountryActionSaga),
    takeLatest(getSettingSdkOCR.TRIGGER, getSettingSdkOCRSaga),
    takeLatest(updateApplication.TRIGGER, updateApplicationSaga),
    takeLatest(closeModalCheckCa.TRIGGER, closeModalCheckCaSaga),
    takeLatest(getInfoCustomerDetail.TRIGGER, getInfoCustomerDetailSaga),
    takeLatest(
      createNiceDigitalAccountAction.TRIGGER,
      createNiceDigitalAccountSaga,
    ),
    takeLatest(getWorkTimeApp.TRIGGER, getWorkTimeAppSaga),
  ]);
}
