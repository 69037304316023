import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { screenType, ROUTE } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { getWorks, submitAdditionalWorkInfo } from './actions';

export function* getWorksSaga() {
  const payload = {
    url: CONSTANT.apiCommon.selections,
    params: {
      category: 'EMPLOYMENTSTATUS',
    },
  };

  yield put(getWorks.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      let listWork = [];

      if (isArray(data)) {
        const labelIcome = {
          nghỉhưu: 'Trợ cấp- Lương hưu hàng tháng',
          kinhdoanh: 'Doanh thu bình quân tháng',
          thấtnghiệp: 'Trợ cấp- Lương hưu hàng tháng',
          bánthờigian: 'Thu nhập hàng tháng',
          toànthờigian: 'Thu nhập hàng tháng',
          'nghỉhưu/thấtnghiệp': 'Trợ cấp- Lương hưu hàng tháng',
        };

        listWork = data.map(_i => {
          const codeName = String(get(_i, 'name', ''))
            .replaceAll(' ', '')
            .toLowerCase();
          return {
            ..._i,
            label: get(labelIcome, `${codeName}`, ''),
          };
        });
      }
      yield put(getWorks.success({ listWork }));
      return;
    }

    yield put(getWorks.failure());
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(getWorks.failure());
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export function* submitAdditionalWorkInfoSaga(action) {
  const payload = {
    url: CONSTANT.apiCommon.addNonPaWork,
    data: get(action, 'payload'),
  };

  yield put(submitAdditionalWorkInfo.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (
      get(meta, 'code') === 'IL-200' &&
      get(data, 'screen') === screenType.WAITING_LOS_RISK_ASSETMENT
    ) {
      history.push(ROUTE.common.WattingLosRiskAssetment);
      return;
    }

    yield put(submitAdditionalWorkInfo.failure());
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(submitAdditionalWorkInfo.failure());
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(getWorks.TRIGGER, getWorksSaga),
    takeLatest(submitAdditionalWorkInfo.TRIGGER, submitAdditionalWorkInfoSaga),
  ]);
}
