import get from 'lodash/get';
import size from 'lodash/size';
import uniqBy from 'lodash/uniqBy';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import { getCardListBenefit, getListCards } from './actions';

export function* getListBenefitsActionSaga() {
  const params = {
    name: '',
    type: '',
    page: 0,
    size: 10,
    sort: 'name',
  };

  const payload = {
    url: CONSTANT.apiCc.benefits,
    params,
  };

  yield put(getCardListBenefit.request());
  try {
    const respond = yield call(Api.get, payload);
    if (size(get(respond, 'data.data', []))) {
      let listSortBenefit = [];
      const { data = [] } = get(respond, 'data', {});
      const listTypeBenefit = uniqBy(data, 'type');

      listTypeBenefit.forEach(elem => {
        listSortBenefit = [
          ...listSortBenefit,
          ...data.filter(_i => _i.type === elem.type),
        ];
      });
      respond.data.data = listSortBenefit;
    }
    yield put(getCardListBenefit.success(respond));
  } catch (error) {
    yield put(getCardListBenefit.failure(error));
  }
}

export function* getListCardsActionSaga(action) {
  const appId = localStorage.getItem('appId') || get(action, 'payload.appId');
  const listIdBenefits = get(action, 'payload.listIdBenefits', []).toString();

  const payload = {
    url: CONSTANT.apiCc.listCard,
    params: {
      'app-id': appId,
      'benefit-ids': listIdBenefits || undefined,
    },
  };

  if (appId) {
    yield put(getListCards.request());
    try {
      const respond = yield call(Api.get, payload);
      yield put(getListCards.success(respond));
    } catch (error) {
      yield put(getListCards.failure(error));
    }
  }
}
export default function* watchAll() {
  yield all([
    takeLatest(getCardListBenefit.TRIGGER, getListBenefitsActionSaga),
    takeLatest(getListCards.TRIGGER, getListCardsActionSaga),
  ]);
}
