import { createRoutine } from 'redux-saga-routines';
import {
  GET_BRANCH_PROVINCE,
  SUBMIT_DELIVERY_ADDRESS,
  SUBMIT_DELIVERY_BRANCH,
} from './constants';

export const getBranchProvince = createRoutine(GET_BRANCH_PROVINCE);
export const submitDeliveryAddress = createRoutine(SUBMIT_DELIVERY_ADDRESS);
export const submitDeliveryBranch = createRoutine(SUBMIT_DELIVERY_BRANCH);
