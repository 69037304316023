import React, { lazy, Suspense } from 'react';

const loadable = (importFunc, { fallback = null, provider }) => {
  const LazyComponent = lazy(importFunc);

  const Provider = provider || React.Fragment;

  return props => (
    <Provider>
      <Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </Suspense>
    </Provider>
  );
};

export default loadable;
