/* eslint-disable no-else-return */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '@utils/services/api.request';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import history from '@utils/history';
import { DOMAIN_CARD } from '@utils/constants';
import * as CONST_GLB from '@utils/services/api.constants';

import { submitRegisterAction, sendOTPAction } from './actions';

// import * as Api from '@utils/services/api.request';

export function* submitRegisterActionSaga(action) {
  const payload = {
    url: `/applications/register`,
    data: action.payload,
  };

  yield put(submitRegisterAction.request());
  try {
    const respond = yield call(Api.postJarvis, payload);
    if (get(respond, 'data.instantLendingFlow')) {
      yield put(submitRegisterAction.success(respond));
      yield put(
        sendOTPAction({ phoneNumber: get(payload, 'data.mobile.phoneNumber') }),
      );
    } else {
      yield put(submitRegisterAction.failure());
      toast.error('Đăng ký không thành công');
    }
  } catch (error) {
    const phoneNumber = get(payload, 'data.mobile.phoneNumber');

    if (get(error, 'id') === 'APP_BELONG_INSTANT_LENDING') {
      yield put(submitRegisterAction.success());
      return yield put(sendOTPAction({ phoneNumber }));
    }

    if (get(error, 'id') === 'APP_BELONG_ONLINE_FLOW') {
      yield put(submitRegisterAction.success());
      sessionStorage.tempPhone = phoneNumber;
      window.location.href = `${DOMAIN_CARD}/re-enter`;
      return;
    }

    if (error.id === 'APP_BELONG_CUSTOMER_FLOW') {
      yield put(submitRegisterAction.success());
      window.location.href = `${DOMAIN_CARD}/jarvis-customer/otp?mobile=${phoneNumber}`;
      return;
    }

    if (error.id === 'APP_BELONG_JC_PARTNER') {
      toast.error('Hồ sơ đã tồn tại JC_PARTNER');
      yield put(submitRegisterAction.failure());
      return;
    }
    toast.error('Đăng ký không thành công');
    yield put(submitRegisterAction.failure());
  }
}
export function* sendOTPActionSaga(action) {
  const { phoneNumber } = action.payload;

  const payload = {
    url: CONST_GLB.apiCommon.otpRequest,
    params: { phoneNumber },
  };

  yield put(sendOTPAction.request());
  try {
    const respond = yield call(Api.get, payload);
    if (respond.data.status === 200) {
      history.push(`/otp?mobile=${phoneNumber}`);
    }
    // yield put(sendOTPAction.success(respond));
  } catch (error) {
    toast.error(get(error, 'message') || 'error system');
    yield put(sendOTPAction.failure(error));
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(submitRegisterAction.TRIGGER, submitRegisterActionSaga),
    takeLatest(sendOTPAction.TRIGGER, sendOTPActionSaga),
  ]);
}
