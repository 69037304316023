import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import { nextScreen } from '@utils/redirect';
import { screenType } from '@utils/constants';
import { getLocalByKey } from '@utils/helpers';

import { checkResultLosRiskAssetment } from './actions';

export function* checkResultLosRiskAssetmentSaga() {
  const appId = getLocalByKey('appId');
  if (appId) {
    const payload = {
      url: CONSTANT.apiCommon.applicationDetail(appId),
    };

    yield put(checkResultLosRiskAssetment.request());
    try {
      const respond = yield call(Api.get, payload);
      const { data, meta } = get(respond, 'data') || {};

      if (
        get(meta, 'code') === 'IL-200' &&
        get(data, 'screen_type') !== screenType.WAITING_LOS_RISK_ASSETMENT
      ) {
        nextScreen({ screen: get(data, 'screen_type') });
        return;
      }

      yield put(checkResultLosRiskAssetment.failure());
    } catch (error) {
      yield put(checkResultLosRiskAssetment.failure());
    }
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(
      checkResultLosRiskAssetment.TRIGGER,
      checkResultLosRiskAssetmentSaga,
    ),
  ]);
}
