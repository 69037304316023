export const key = 'register';

export const SUBMIT_LOGIN = 'app/register/VERIFY_OTP_ACTION';
export const SEND_OTP_ACTION = 'app/register/SEND_OTP_ACTION';

export const ListSession = [
  'utm_source',
  'utm_medium',
  // 'utm_campaign',
  'utm_content',
  'utm_term',
  'saleAgentId',
  'partner',
  'benefit',
  'cardid',
  'campaignid',
  'agencyid',
  'back',
  'direct',
];
