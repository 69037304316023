import { ROUTE } from '@utils/constants';

import * as PageCommons from '@containers/Commons';

const routerCommon = [
  {
    exact: true,
    path: ROUTE.common.Home,
    component: PageCommons.Home,
  },
  {
    exact: true,
    path: ROUTE.common.Otp,
    component: PageCommons.Otp,
  },
  {
    exact: true,
    path: ROUTE.common.Register,
    component: PageCommons.Register,
  },
  {
    exact: true,
    path: ROUTE.common.Reject,
    component: PageCommons.Reject,
  },
  {
    exact: true,
    path: ROUTE.common.Liveness,
    component: PageCommons.Liveness,
  },
  {
    exact: true,
    path: ROUTE.common.ChangeDevice,
    component: PageCommons.ChangeDevice,
  },
  {
    exact: true,
    path: ROUTE.common.WaitingCreateAppLos,
    component: PageCommons.WaitingCreateAppLos,
  },
  {
    exact: true,
    path: ROUTE.common.OCRConfirm,
    component: PageCommons.OCRConfirm,
  },
  {
    exact: true,
    path: ROUTE.common.OCRInstruction,
    component: PageCommons.OCRInstruction,
  },
  {
    exact: true,
    path: ROUTE.common.OCRCaptureBack,
    component: PageCommons.OCRCaptureBack,
  },
  {
    exact: true,
    path: ROUTE.common.OCRCaptureFront,
    component: PageCommons.OCRCaptureFront,
  },
  {
    exact: true,
    path: ROUTE.common.WattingLosAfterOCR,
    component: PageCommons.WaitingPreCheckPostOCR,
  },
  {
    exact: true,
    path: ROUTE.common.AdditionalInfo,
    component: PageCommons.CustomerNonPaInfo,
  },
  {
    exact: true,
    path: ROUTE.common.AdditionalWorkInfo,
    component: PageCommons.CustomerNonPaWork,
  },
  {
    exact: true,
    path: ROUTE.common.AdditionalInfoPA,
    component: PageCommons.CustomerPaInfo,
  },
  {
    exact: true,
    path: ROUTE.common.WattingLosRiskAssetment,
    component: PageCommons.WaitingLosRiskAssetment,
  },
  {
    exact: true,
    path: ROUTE.common.WaitingTPResult,
    component: PageCommons.WattingTpResult,
  },
  {
    exact: true,
    path: ROUTE.common.VKYCConnection,
    component: PageCommons.VKYCConnection,
  },
  {
    exact: true,
    path: ROUTE.common.GuidelineSettingCamera,
    component: PageCommons.GuidelineSettingCamera,
  },
  {
    exact: true,
    path: ROUTE.common.NFCGuideline,
    component: PageCommons.NFCGuideline,
  },
  {
    exact: true,
    path: ROUTE.common.RejectNEO,
    component: PageCommons.RejectNEO,
  },
  {
    exact: true,
    path: ROUTE.common.OCRCaptureQR,
    component: PageCommons.OCRCaptureQR,
  },
];

export default routerCommon;
