import { produce } from 'immer';
import get from 'lodash/get';

import { checkDataLiveness, getSettingSDK } from './actions';

export const initialState = {
  loading: false,
  settingSDK: null,
  isShowRetry: false,
  messageError: null,
  titleTakeShot: 'Vui lòng làm theo hướng dẫn để nhận diện khuôn mặt',
};

/* eslint-disable default-case, no-param-reassign */
const LivenessReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getSettingSDK.REQUEST: {
        draft.loading = true;
        draft.settingSDK = null;
        break;
      }
      case getSettingSDK.SUCCESS: {
        draft.loading = false;
        draft.settingSDK = get(action, 'payload', {});
        break;
      }
      case getSettingSDK.FAILURE: {
        draft.loading = false;
        draft.settingSDK = null;
        break;
      }

      case checkDataLiveness.REQUEST: {
        draft.loading = true;
        draft.messageError = '';
        draft.isShowRetry = false;
        draft.titleTakeShot = get(action, 'payload.titleTakeShot');
        break;
      }
      case checkDataLiveness.SUCCESS: {
        // draft.loading = false;
        break;
      }
      case checkDataLiveness.FAILURE: {
        draft.loading = false;
        draft.isShowRetry = get(action, 'payload.isShowRetry');
        draft.titleTakeShot = get(action, 'payload.titleTakeShot');
        draft.messageError = get(action, 'payload.messageError', null);
        break;
      }
    }
  });

export default LivenessReducer;
