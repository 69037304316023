import React from 'react';
import * as IMG from '@images';

import SettingIOSConatiner from './Styled';

const DATE_CHANGE_BROWSER = [
  {
    name: 'Safari',
    ic: IMG.IC_SAFARI,
    isIOS: true,
  },
  {
    name: 'Chrome',
    ic: IMG.IC_CHROME,
    isAllow: true,
  },
  {
    name: 'Firefox',
    ic: IMG.IC_FIREFOX,
    isIOS: false,
  },
];

export default function SettingIOS({ isIOS }) {
  return (
    <SettingIOSConatiner>
      <div className="popupContent">
        <div className="changeBrowser">
          <div className="step step1">
            <div className="devider devider1">
              <p>Thoát trình duyệt hiện tại</p>
            </div>
          </div>
          <div className="deviderCol" />
          <div className="step step2">
            <div className="devider devider2">
              <p>
                Trên thiết bị của bạn, mở ứng dụng Cài đặt (Setting){' '}
                <img src={IMG.IC_SETTING_IOS} alt="setting" />
              </p>
              <p>
                Chọn quyền riêng tư (Privacy & Security){' '}
                <img src={IMG.IC_HAND_IOS} alt="setting" />
              </p>
              <p>
                Chọn Camera <img src={IMG.IC_CAMERA_IOS} alt="setting" />{' '}
              </p>
            </div>
          </div>
          <div className="deviderCol" />
          <div className="step step3">
            <div className="devider3">
              <p>
                Chọn cấp quyền truy cập cho trình duyệt mà bạn đang thực hiện
                đăng ký
              </p>
              <div className="groupBrowser">
                {DATE_CHANGE_BROWSER.map(_i => ({
                  ..._i,
                  isShow: isIOS === _i.isIOS || _i.isAllow,
                })).map(
                  browser =>
                    browser.isShow && (
                      <div key={browser.name} className="browser">
                        <img src={browser.ic} alt={browser.name} />
                        <span>{browser.name}</span>
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingIOSConatiner>
  );
}
