import { produce } from 'immer';
import get from 'lodash/get';

import { checkImageOCRQRAction } from './actions';

export const initialState = {
  loading: false,
  isRetry: false,
  message: '',
};

/* eslint-disable default-case, no-param-reassign */
const OcrFrontReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case checkImageOCRQRAction.REQUEST: {
        draft.loading = true;
        break;
      }
      case checkImageOCRQRAction.SUCCESS: {
        draft.loading = false;
        break;
      }
      case checkImageOCRQRAction.FAILURE: {
        draft.loading = false;
        draft.isRetry = true;
        draft.message = get(action, 'payload.message');
        break;
      }
      case checkImageOCRQRAction.FULFILL: {
        draft.message = "";
        draft.loading = false;
        draft.isRetry = false;
        break;
      }
    }
  });
export default OcrFrontReducer;
