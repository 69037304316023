import get from 'lodash/get';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { nextScreen } from '@utils/redirect';
import { screenType, CODE_STATUS } from '@utils/constants';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

// import { checkSafari } from '@utils/helpers';

import * as actionNfc from './actions';

export function* checkVerifyNFCActionSaga({ payload }) {
  try {
    yield put(actionNfc.checkVerifyNFCAction.request(payload));

    const appId = localStorage.getItem('appId');
    if (appId) {
      const request = {
        url: CONSTANT.apiCommon.applicationDetail(appId),
      };

      const respond = yield call(Api.get, request);
      const { data, meta } = get(respond, 'data') || {};

      if (
        get(meta, 'code') === CODE_STATUS.IL200 &&
        get(data, 'screen_type') !== screenType.NFC_GUIDE
      ) {
        yield put(actionNfc.checkVerifyNFCAction.success());
        nextScreen({ screen: data.screen_type });
        return;
      }

      if (get(payload, 'loading')) {
        yield put(actionNfc.checkVerifyNFCAction.success({ isNFCFail: true }));
        return;
      }
      yield put(actionNfc.checkVerifyNFCAction.failure());
    }
  } catch (error) {
    yield put(actionNfc.checkVerifyNFCAction.failure());
  }
}

export function* getLinkNFCQrcodeActionSaga({ payload }) {
  try {
    yield put(actionNfc.getLinkNFCQrcodeAction.request(payload));

    const appId = localStorage.getItem('appId');
    if (appId) {
      const request = {
        url: CONSTANT.apiCommon.nfcQrCode,
        params: {
          appId,
          subSystem: 'IL_UPL',
        },
      };

      const respond = yield call(Api.get, request);
      const { data, meta } = get(respond, 'data') || {};

      if (get(meta, 'code') === CODE_STATUS.IL200) {
        yield put(
          actionNfc.getLinkNFCQrcodeAction.success({
            image: get(data, 'image', null),
            deeplink: get(data, 'deeplink', null),
            time_expired: get(data, 'time_expired', 60),
          }),
        );
        return;
      }

      toast.error(get(data, 'success_message', MESSAGE_SYSTEM.notFound));
      yield put(actionNfc.getLinkNFCQrcodeAction.failure());
    }
  } catch (error) {
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.notFound));
    yield put(actionNfc.getLinkNFCQrcodeAction.failure());
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(
      actionNfc.checkVerifyNFCAction.TRIGGER,
      checkVerifyNFCActionSaga,
    ),
    takeLatest(
      actionNfc.getLinkNFCQrcodeAction.TRIGGER,
      getLinkNFCQrcodeActionSaga,
    ),
  ]);
}
