import { ROUTE } from '@utils/constants';

import * as PageCC from '@containers/CC';

const routerCC = [
  {
    exact: true,
    path: ROUTE.cc.EtcConfirm,
    component: PageCC.ETCConfirmCC,
  },
  {
    exact: true,
    path: ROUTE.cc.EtcCompleted,
    component: PageCC.ETCCompletedCC,
  },
  {
    exact: true,
    path: ROUTE.cc.CardList,
    component: PageCC.CardListCC,
  },
  {
    exact: true,
    path: ROUTE.cc.CardOffer,
    component: PageCC.CardOfferCC,
  },
  {
    exact: true,
    path: ROUTE.cc.CardDetail,
    component: PageCC.CardDetailCC,
  },
  {
    exact: true,
    path: ROUTE.cc.Introduction,
    component: PageCC.IntroductionCC,
  },
  {
    exact: true,
    path: ROUTE.cc.CardProfile,
    component: PageCC.CardProfileCC,
  },
  {
    exact: true,
    path: ROUTE.cc.DeliveryAddress,
    component: PageCC.DeliveryAddressCC,
  },
  {
    exact: true,
    path: ROUTE.cc.SecurityQuestion,
    component: PageCC.SecurityQuestionsCC,
  },
  {
    exact: true,
    path: ROUTE.cc.Econtract,
    component: PageCC.ConfirmEcontractCC,
  },
  {
    exact: true,
    path: ROUTE.cc.OtpEcontract,
    component: PageCC.OtpEcontractCC,
  },
  {
    exact: true,
    path: ROUTE.cc.RegisterCompleted,
    component: PageCC.RegisterCompletedCC,
  },
  {
    exact: true,
    path: ROUTE.cc.WaitingLosDisbursement,
    component: PageCC.WaitingLosDisbursementCC,
  },
  {
    exact: true,
    path: ROUTE.cc.WaitingProcessGenForm,
    component: PageCC.WaitingProcessGenFormCC,
  },
];

export default routerCC;
