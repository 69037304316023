export const key = 'upl_e_contract';

export const typeModalPdf = {
  INSURANCE: 'insurance',
  ECONTRACT: 'econtract',
};

export const titleModal = {
  INSURANCE: 'Xem yêu cầu bảo hiểm',
  ECONTRACT: 'Xem hợp đồng cho vay vốn',
};

export const CONFIRM_ECONTRACT_UPL = 'app/upl/econtract/CONFIRM';
export const GET_FILE_ECONTRACT_UPL = 'app/upl/econtract/GET_FILE';
export const CANCEL_LOAN_ECONTRACT_UPL =
  'app/upl/econtract/CANCEL_LOAN_ECONTRACT_UPL';
