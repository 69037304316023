import get from 'lodash/get';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';

import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import history from '@utils/history';
import * as COOKIE from '@utils/cookie';
import { MESSAGE_SYSTEM } from '@utils/message';
import { ROUTE, TOKEN_KEY, screenType } from '@utils/constants';

import {
  resendOTPEcontractUplAction,
  verifyOTPEcontractUplAction,
} from './actions';

export function* resendOTPEcontractUplActionSaga(action) {
  const payload = {
    url: CONSTANT.apiUpl.eContractOtp,
    params: get(action, 'payload', {}),
  };

  yield put(resendOTPEcontractUplAction.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data } = get(respond, 'data') || {};

    switch (get(data, 'screen')) {
      case screenType.OUT_SIDE_WORKING_HOUR: {
        history.push(ROUTE.upl.OutSideWorkingHour);
        break;
      }
      default: {
        break;
      }
    }

    yield put(resendOTPEcontractUplAction.success(respond));
  } catch (error) {
    yield put(resendOTPEcontractUplAction.failure(error));
  }
}

export function* verifyOTPEcontractUplActionSaga(action) {
  const payload = {
    url: CONSTANT.apiUpl.verifyEcontract,
    data: get(action, 'payload', {}),
  };

  yield put(verifyOTPEcontractUplAction.request());
  try {
    const respond = yield call(Api.post, payload);

    const { data, meta } = get(respond, 'data') || {};
    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(verifyOTPEcontractUplAction.success());

      switch (get(data, 'screen')) {
        case screenType.WAITING_LOS_DISBURSEMENT_LOAN: {
          const appId = get(jwtDecode(get(data, 'token')), 'appId');

          localStorage.setItem('appId', appId);
          COOKIE.setCookie(TOKEN_KEY, data.token, 30);
          localStorage.setItem(TOKEN_KEY, `Bearer ${data.token}`);

          history.push(ROUTE.upl.WaitingLosDisbursement);
          return;
        }
        case screenType.OVERDUE: {
          history.push(ROUTE.upl.Overdue);
          return;
        }
        case screenType.OUT_SIDE_WORKING_HOUR: {
          history.push(ROUTE.upl.OutSideWorkingHour);
          return;
        }
        case screenType.REJECT: {
          history.push(ROUTE.common.Reject);
          return;
        }
        case screenType.NFC_GUIDE: {
          history.push(ROUTE.common.NFCGuideline);
          return;
        }
        default: {
          toast.error(get(data, 'message', MESSAGE_SYSTEM.error_01));
          break;
        }
      }
      return;
    }

    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(verifyOTPEcontractUplAction.failure(error));
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(
      resendOTPEcontractUplAction.TRIGGER,
      resendOTPEcontractUplActionSaga,
    ),
    takeLatest(
      verifyOTPEcontractUplAction.TRIGGER,
      verifyOTPEcontractUplActionSaga,
    ),
  ]);
}
