import { produce } from 'immer';
import get from 'lodash/get';

import { getRelationships, submitAddSecInfo } from './actions';

export const initialState = {
  loading: false,
  listRelationship: [],
};

/* eslint-disable default-case, no-param-reassign */
const SecurityQuestionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getRelationships.REQUEST: {
        draft.loading = true;
        draft.listRelationship = [];
        break;
      }
      case getRelationships.SUCCESS: {
        draft.loading = false;
        draft.listRelationship = get(action, 'payload.listRelationship');
        break;
      }
      case getRelationships.FAILURE: {
        draft.loading = false;
        draft.listRelationship = [];
        break;
      }

      case submitAddSecInfo.REQUEST: {
        draft.loading = true;
        break;
      }
      case submitAddSecInfo.SUCCESS: {
        draft.loading = false;
        break;
      }
      case submitAddSecInfo.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });

export default SecurityQuestionReducer;
