import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import get from 'lodash/get';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { ROUTE, screenType } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { getSettingSDK, checkDataLiveness } from './actions';

export function* getSettingSDKSaga() {
  const payload = {
    url: CONSTANT.apiCommon.settingSDK,
  };

  yield put(getSettingSDK.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(respond, 'status') === 200 && data) {
      yield put(getSettingSDK.success({ data }));
    } else {
      toast.error(get(meta, 'message') || 'error system');
    }
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getSettingSDK.failure());
  }
}

export function* checkDataLivenessSaga(action) {
  const payload = {
    url: CONSTANT.apiCommon.liveness,
    data: get(action, 'payload.formData', null),
  };

  yield put(
    checkDataLiveness.request({
      titleTakeShot:
        'Hệ thống đang thực hiện nhận diện khuôn mặt. <br/> Vui lòng không tắt hoặc làm mới (F5) trình duyệt.',
    }),
  );

  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      switch (get(data, 'screen')) {
        case 'RETRY': {
          yield put(
            checkDataLiveness.failure({
              isShowRetry: true,
              messageError: get(data, 'message', ''),
              titleTakeShot: 'Hình ảnh khuôn mặt đã được nhận diện của bạn:',
            }),
          );
          return;
        }
        case screenType.REJECT: {
          yield put(checkDataLiveness.success());
          history.push(ROUTE.common.Reject);
          break;
        }
        case screenType.E_CONTRACT: {
          yield put(checkDataLiveness.success());
          history.push(ROUTE.cc.Econtract);
          break;
        }
        case screenType.WAITING_LOS_DISBURSEMENT: {
          yield put(checkDataLiveness.success());
          history.push(ROUTE.cc.WaitingLosDisbursement);
          break;
        }
        case screenType.CHECKING_INFO_UPL: {
          yield put(checkDataLiveness.success());
          history.push(ROUTE.upl.WaitingCheckingInfo);
          break;
        }
        default: {
          toast.error(get(data, 'message', MESSAGE_SYSTEM.error_01));
          break;
        }
      }
      return;
    }

    yield put(
      checkDataLiveness.failure({
        isShowRetry: true,
        titleTakeShot: 'Hình ảnh khuôn mặt đã được nhận diện của bạn:',
      }),
    );
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(
      checkDataLiveness.failure({
        isShowRetry: true,
        titleTakeShot: 'Hình ảnh khuôn mặt đã được nhận diện của bạn:',
      }),
    );

    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export default function* watchAll() {
  yield all([takeLatest(getSettingSDK.TRIGGER, getSettingSDKSaga)]);
  yield all([takeLatest(checkDataLiveness.TRIGGER, checkDataLivenessSaga)]);
}
