import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import getLd from 'lodash/get';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { ROUTE, screenType } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { confirmLoanProfile } from './actions';

export function* confirmLoanProfileSaga(body) {
  const payload = {
    url: CONSTANT.apiUpl.confirmApproved,
    data: getLd(body, 'payload', {}),
  };

  yield put(confirmLoanProfile.request());
  try {
    const respond = yield call(Api.put, payload);
    const { data, meta } = getLd(respond, 'data') || {};

    if (getLd(meta, 'code') === 'IL-200') {
      yield put(confirmLoanProfile.success());

      switch (getLd(data, 'screen')) {
        case screenType.OCR_GUIDE: {
          history.push(ROUTE.common.OCRInstruction);
          break;
        }
        default: {
          toast.error(getLd(meta, 'message', MESSAGE_SYSTEM.error_01));
          break;
        }
      }
      return;
    }

    toast.error(getLd(meta, 'message', MESSAGE_SYSTEM.error_02));
    yield put(confirmLoanProfile.failure());
  } catch (error) {
    toast.error(getLd(error, 'meta.message', MESSAGE_SYSTEM.default));
    yield put(confirmLoanProfile.failure());
  }
}

export default function* watchAll() {
  yield all([takeLatest(confirmLoanProfile.TRIGGER, confirmLoanProfileSaga)]);
}
