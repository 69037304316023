// Load the favicon and the .htaccess file
import 'sanitize.css/sanitize.css';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from "react-dom/client";
import { ConnectedRouter } from 'connected-react-router';

// component
import App from '@App';
import LanguageProvider from '@LanguageProvider';
import history from '@utils/history';
import ThemeProvider from '@utils/theme';
import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("app");
const ROOT = createRoot(MOUNT_NODE);

const render = (messages) => {
  ROOT.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>
  );
};

if (import.meta.hot) {
  import.meta.hot.accept(["./i18n", "@App"], () => {
    render(translationMessages);
  });
}

if (!window.Intl) {
  import("intl")
    .then(() =>
      Promise.all([
        import("intl/locale-data/jsonp/en.js"),
        import("intl/locale-data/jsonp/de.js"),
      ])
    )
    .then(() => render(translationMessages));
} else {
  render(translationMessages);
}