import { produce } from 'immer';

import get from 'lodash/get';
import {
  resendOTPEcontractUplAction,
  verifyOTPEcontractUplAction,
} from './actions';

export const initialState = {
  loading: false,
  msgError: null,
  flagError: false,
  codeErr: null,
  keyRedirect: null,
  appIdOtp: null,
};

/* eslint-disable default-case, no-param-reassign */
const OtpReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case resendOTPEcontractUplAction.REQUEST: {
        draft.loading = true;
        draft.flagError = false;
        draft.msgError = null;
        draft.codeErr = null;

        break;
      }
      case resendOTPEcontractUplAction.SUCCESS: {
        draft.loading = false;
        draft.flagError = false;
        draft.msgError = null;
        draft.codeErr = null;
        break;
      }
      case resendOTPEcontractUplAction.FAILURE: {
        draft.loading = false;
        draft.msgError = action.payload.message;
        draft.flagError = true;
        draft.codeErr = action.payload.error;
        break;
      }

      case verifyOTPEcontractUplAction.REQUEST: {
        draft.loading = true;
        draft.keyRedirect = null;
        draft.flagError = false;
        draft.msgError = null;
        draft.codeErr = null;
        break;
      }
      case verifyOTPEcontractUplAction.SUCCESS: {
        draft.loading = false;
        draft.flagError = false;
        break;
      }
      case verifyOTPEcontractUplAction.FAILURE: {
        draft.loading = false;
        draft.msgError = get(action, 'payload.meta.message') || null;
        draft.codeErr = action.payload.meta.code;
        draft.keyRedirect = null;
        draft.flagError = true;
        break;
      }
    }
  });
export default OtpReducer;
