/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
// import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { REFERER_KEY, DOMAIN_VAY, DOMAIN_CARD } from '@utils/constants';

import Router from '@routers';

import Footer from '@components/Footer';
import Header from '@components/Header';
import Loading from '@components/Loading';

import ScrolToTop from '@components/ScrollToTop';
import MainLayout from '@layouts/MainLayout';

import NotFound from '@containers/404';

import GlobalStyle from '../global-styles';

import reducer from './reducer';
import saga from './saga';

export default function App() {
  useInjectReducer({ key: 'global', reducer });
  useInjectSaga({ key: 'global', saga });

  const { loading } = useSelector(state => state.global);

  useEffect(() => {
    let referrerUrl = document.referrer || '';
    const lastIndexOf = String(referrerUrl).lastIndexOf('/');

    referrerUrl = String(referrerUrl).substring(0, lastIndexOf);

    if ([DOMAIN_CARD, DOMAIN_VAY].includes(referrerUrl)) {
      sessionStorage.setItem(REFERER_KEY, referrerUrl);
    }
  }, []);

  return (
    <React.Fragment>
      <Loading loading={loading} />

      <Helmet
        titleTemplate="Mở thẻ tín dụng 100% online | VPBank"
        defaultTitle="Mở thẻ tín dụng 100% online | VPBank"
      >
        <meta
          name="description"
          content="Mở thẻ tín dụng 100% online | VPBank"
        />
      </Helmet>

      <MainLayout>
        <Header />
        <ScrolToTop />
        <Router />

        {/* <Switch>
          <Route path="*" component={NotFound} />
        </Switch> */}
        <ToastContainer delay={3000} style={{ zIndex: 1000 }} />
      </MainLayout>
      <GlobalStyle />
      <Footer />
      <span id="codeError" className="_codeError" />
    </React.Fragment>
  );
}
