import { produce } from 'immer';
import get from 'lodash/get';

import { getMaritals, submitAdditionalInfo } from './actions';

export const initialState = {
  loading: false,
  listMarital: [],
};

/* eslint-disable default-case, no-param-reassign */
const AdditionalInfoPAReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getMaritals.REQUEST: {
        draft.loading = true;
        draft.listMarital = [];
        break;
      }
      case getMaritals.SUCCESS: {
        draft.loading = false;
        draft.listMarital = get(action, 'payload.listMarital');
        break;
      }
      case getMaritals.FAILURE: {
        draft.loading = false;
        draft.listMarital = [];
        break;
      }

      case submitAdditionalInfo.REQUEST: {
        draft.loading = true;
        break;
      }
      case submitAdditionalInfo.SUCCESS: {
        draft.loading = false;
        break;
      }
      case submitAdditionalInfo.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });

export default AdditionalInfoPAReducer;
