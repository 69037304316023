import styled from 'styled-components';

const ButtonStyle = styled.div`
  border-radius: 4px;

  &.btn-none-bg {
    .MuiButtonBase-root {
      background: #ffffff;
      border: 1px solid #00b74f;
      color: #00b74f;

      .textLinearGradient {
        background: linear-gradient(91.03deg, #00b74f 0%, #009540 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
  &.btnConfirm {
    .MuiButton-root {
      &:hover {
        background: #00b74f;
        opacity: 0.8;
      }
    }
  }

  .MuiButtonBase-root {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    border-radius: 4px;
    // background: linear-gradient(91.03deg, #00b74f 0%, #009540 100%);
    background: #00b74f;
    font-size: 16px;
    line-height: 17px;
    text-transform: none;
    font-family: 'SVN-Gilroy';

    &.Mui-disabled {
      color: #fff;
      opacity: 0.4;
    }
  }
`;

export default ButtonStyle;
