import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFound from '@containers/404';

import routerCC from './cc';
import routerUpl from './upl';
import routerCommon from './common';

const Router = () => (
  <Switch>
    {[...routerCommon, ...routerCC, ...routerUpl].map((router, index) => (
      <Route key={index} {...router} />
    ))}

    <Route path="*" component={NotFound} />
  </Switch>
);

export default Router;
