import { produce } from 'immer';

import get from 'lodash/get';
import { getRelationshipsUPL, submitCompletepProfileAction } from './actions';

export const initialState = {
  loading: false,
  listRelationshipUPL: [],
};

/* eslint-disable default-case, no-param-reassign */
const CardProfileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getRelationshipsUPL.REQUEST: {
        draft.loading = true;
        draft.listRelationshipUPL = [];
        break;
      }
      case getRelationshipsUPL.SUCCESS: {
        draft.loading = false;
        draft.listRelationshipUPL = get(action, 'payload.listRelationshipUPL');
        break;
      }
      case getRelationshipsUPL.FAILURE: {
        draft.loading = false;
        draft.listRelationshipUPL = [];
        break;
      }
      case submitCompletepProfileAction.REQUEST: {
        draft.loading = true;
        break;
      }
      case submitCompletepProfileAction.SUCCESS: {
        draft.loading = false;
        break;
      }
      case submitCompletepProfileAction.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });

export default CardProfileReducer;
