import React from 'react';
import loadable from '@utils/loadable';

import { useInjectReducer } from '@utils/injectReducer';
import { useInjectSaga } from '@utils/injectSaga';

import LoadingIndicator from '@components/commons/LoadingIndicator';

import saga from './store/saga';
import reducer from './store/reducer';
import { key } from './store/constants';

const Provider = ({ children }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  return <>{children}</>;
};

const OtpEcontractUpl = loadable(() => import('./Main'), {
  fallback: <LoadingIndicator />,
  provider: Provider,
});

export default OtpEcontractUpl;
