import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { nextScreen } from '@utils/redirect';

import * as Api from '@utils/services/api.request';
import * as CONST_GLB from '@utils/services/api.constants';

import { waitingCreateAppInTp } from './actions';

export function* waitingCreateAppInTpSaga(action) {
  const payload = {
    url: CONST_GLB.apiCc.waitingTpc,
    data: get(action, 'payload'),
  };

  yield put(waitingCreateAppInTp.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};
    if (get(meta, 'code') === 'IL-200' && data) {
      nextScreen({ screen: get(data, 'screen') });
    } else {
      yield put(waitingCreateAppInTp.failure());
    }
    yield put(waitingCreateAppInTp.success(respond));
  } catch (error) {
    yield put(waitingCreateAppInTp.failure());
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(waitingCreateAppInTp.TRIGGER, waitingCreateAppInTpSaga),
  ]);
}
