import getLd from 'lodash/get';
import { produce } from 'immer';

import * as actionNfc from './actions';

export const initialState = {
  loading: false,
  isNFCFail: false,

  infoQRCode: {
    image: null,
    deeplink: null,
    time_expired: 0,
  },
};

/* eslint-disable default-case, no-param-reassign */
const VideoCallReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      // checkVerifyNFCAction
      case actionNfc.checkVerifyNFCAction.REQUEST: {
        draft.isNFCFail = false;
        draft.loading = getLd(action, 'payload.loading', false);
        break;
      }
      case actionNfc.checkVerifyNFCAction.SUCCESS: {
        draft.loading = false;
        draft.isNFCFail = getLd(action, 'payload.isNFCFail', false);
        break;
      }
      case actionNfc.checkVerifyNFCAction.FAILURE: {
        draft.loading = false;
        break;
      }

      // getLinkNFCQrcodeAction
      case actionNfc.getLinkNFCQrcodeAction.REQUEST: {
        draft.loading = getLd(action, 'payload.loading', false);
        draft.infoQRCode = {
          ...state.infoQRCode,
          time_expired: 0,
        };
        break;
      }
      case actionNfc.getLinkNFCQrcodeAction.SUCCESS: {
        draft.loading = false;
        draft.infoQRCode = getLd(action, 'payload', {});
        break;
      }
      case actionNfc.getLinkNFCQrcodeAction.FAILURE: {
        draft.loading = false;
        draft.infoQRCode = {
          ...state.infoQRCode,
          time_expired: 60,
        };
        break;
      }
    }
  });
export default VideoCallReducer;
