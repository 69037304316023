/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import logo from '@assets/logo.svg';
// import supportIcon from '@assets/costumer_support.svg';
import { useHistory } from 'react-router';
import get from 'lodash/get';
import { HeaderWraper } from './style';

export default function header() {
  const [checkPath, setCheckPath] = useState(false);
  const router = useHistory();

  useEffect(() => {
    if (router.location.pathname === '/card-detail') {
      setCheckPath(true);
    }
  }, [get(router, 'location.pathname')]);

  return (
    <HeaderWraper checkPath={checkPath}>
      <div className="header">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        {/* <div>
        <img
          className='supportIcon2
          src={supportIcon}
          alt="qna"
          aria_id="ok"
        />
      </div> */}
      </div>
    </HeaderWraper>
  );
}
