import { produce } from 'immer';

import { resultCreateAppLos } from './actions';

export const initialState = {
  loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const WaitingCreateAppLosReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case resultCreateAppLos.REQUEST: {
        draft.loading = true;
        break;
      }
      case resultCreateAppLos.SUCCESS: {
        draft.loading = false;
        break;
      }
      case resultCreateAppLos.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });
export default WaitingCreateAppLosReducer;
