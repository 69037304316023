// import { IntlProvider } from "react-intl";
import enTranslationMessages from "./translations/en.json";
import viTranslationMessages from "./translations/vi.json";

const DEFAULT_LOCALE = "vi";

// Danh sách ngôn ngữ
const appLocales = ["en", "vi"];

// Hàm định dạng thông điệp dịch
const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};

  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };

  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

// Định nghĩa các thông điệp dịch
const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  de: formatTranslationMessages("de", viTranslationMessages),
};

export {
  appLocales,
  DEFAULT_LOCALE,
  translationMessages,
  formatTranslationMessages,
};
