import { produce } from 'immer';

import get from 'lodash/get';
import {
  getFirstDatedebtAction,
  getInfoLoanOfferAction,
  submitLoanOfferAction,
} from './actions';

export const initialState = {
  loading: false,
  loadingLoanOffer: false,
  infoLoanOffer: {},
  listFirstDateDebt: [],
  listAccountNumber: [],
  retry: false,
};

/* eslint-disable default-case, no-param-reassign */
const CardProfileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getInfoLoanOfferAction.REQUEST: {
        draft.retry = false;
        draft.loadingLoanOffer = true;
        draft.infoLoanOffer = {};
        break;
      }
      case getInfoLoanOfferAction.SUCCESS: {
        draft.retry = false;
        draft.loadingLoanOffer = false;
        draft.infoLoanOffer = get(action, 'payload.data');
        draft.listAccountNumber = get(action, 'payload.listAccountNumber');
        break;
      }
      case getInfoLoanOfferAction.FAILURE: {
        draft.loadingLoanOffer = false;
        draft.infoLoanOffer = {};
        if (get(action, 'payload.meta.code') === 'IL-5032') {
          draft.retry = true;
        }
        break;
      }
      case getFirstDatedebtAction.REQUEST: {
        draft.loading = true;
        draft.listFirstDateDebt = [];
        break;
      }
      case getFirstDatedebtAction.SUCCESS: {
        draft.loading = false;
        draft.listFirstDateDebt = get(action, 'payload.data');
        break;
      }
      case getFirstDatedebtAction.FAILURE: {
        draft.loading = false;
        break;
      }
      case submitLoanOfferAction.REQUEST: {
        draft.loading = true;
        break;
      }
      case submitLoanOfferAction.SUCCESS: {
        draft.loading = false;
        break;
      }
      case submitLoanOfferAction.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });

export default CardProfileReducer;
