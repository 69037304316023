import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import history from '@utils/history';
import { ROUTE, screenType } from '@utils/constants';
import { getLocalByKey } from '@utils/helpers';

import { checkResultProcessGenEcontract } from './actions';

export function* checkResultProcessGenEcontractSaga() {
  const token = getLocalByKey('token');

  if (token) {
    const payload = {
      url: CONSTANT.apiUpl.proccesingGenEcontract,
      data: null,
    };

    yield put(checkResultProcessGenEcontract.request());
    try {
      const respond = yield call(Api.post, payload);
      const { data, meta } = get(respond, 'data') || {};

      if (get(meta, 'code') === 'IL-200') {
        switch (get(data, 'screen')) {
          case screenType.LIVE_NESS_GUIDE: {
            history.push(ROUTE.common.Liveness);
            break;
          }
          case screenType.VKYC_GUIDELINE: {
            history.push(ROUTE.common.VKYCConnection);
            break;
          }
          default: {
            break;
          }
        }
        return;
      }

      yield put(checkResultProcessGenEcontract.failure());
    } catch (error) {
      yield put(checkResultProcessGenEcontract.failure());
    }
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(
      checkResultProcessGenEcontract.TRIGGER,
      checkResultProcessGenEcontractSaga,
    ),
  ]);
}
