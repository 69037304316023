import React from 'react';
import { Button } from '@mui/material';

import ButtonStyle from './Styled';

export default function ButtonILD({
  children,
  disabled = false,
  btnConfirm = false,
  ...res
}) {
  return (
    <ButtonStyle className={btnConfirm ? 'btnConfirm' : 'btn-none-bg'}>
      <Button disabled={disabled} {...res}>
        {children}
      </Button>
    </ButtonStyle>
  );
}
