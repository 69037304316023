import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import ProgressStyle from './Styled';

export default function LinearProgressILD({ value = 0 }) {
  return (
    <ProgressStyle>
      <LinearProgress variant="determinate" value={value} />
    </ProgressStyle>
  );
}
