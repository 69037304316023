import { produce } from 'immer';
import get from 'lodash/get';

import { getWorks, submitAdditionalWorkInfo } from './actions';

export const initialState = {
  loading: false,
  listWork: [],
};

/* eslint-disable default-case, no-param-reassign */
const AdditionalWorkInfoReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getWorks.REQUEST: {
        draft.loading = true;
        draft.listWork = [];
        break;
      }
      case getWorks.SUCCESS: {
        draft.loading = false;
        draft.listWork = get(action, 'payload.listWork');
        break;
      }
      case getWorks.FAILURE: {
        draft.loading = false;
        draft.listWork = [];
        break;
      }

      case submitAdditionalWorkInfo.REQUEST: {
        draft.loading = true;
        break;
      }
      case submitAdditionalWorkInfo.SUCCESS: {
        draft.loading = false;
        break;
      }
      case submitAdditionalWorkInfo.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });

export default AdditionalWorkInfoReducer;
