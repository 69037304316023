import get from 'lodash/get';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { nextScreen } from '@utils/redirect';
import { getLocalByKey } from '@utils/helpers';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import { resultCreateAppLos } from './actions';

export function* resultCreateAppLosSaga() {
  const appId = getLocalByKey('appId');

  if (appId) {
    const payload = {
      url: CONSTANT.apiCommon.applicationDetail(appId),
    };

    yield put(resultCreateAppLos.request());
    try {
      const respond = yield call(Api.get, payload);
      const { data, meta } = get(respond, 'data') || {};

      if (
        get(meta, 'code') === 'IL-200' &&
        get(data, 'screen_type') &&
        get(data, 'processing_type')
      ) {
        nextScreen({ screen: get(data, 'screen_type') });
        return;
      }

      yield put(resultCreateAppLos.failure());
    } catch (error) {
      toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
      yield put(resultCreateAppLos.failure());
    }
  }
}

export default function* watchAll() {
  yield all([takeLatest(resultCreateAppLos.TRIGGER, resultCreateAppLosSaga)]);
}
