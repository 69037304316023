import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import { nextScreen } from '@utils/redirect';
import { stepType, screenType } from '@utils/constants';
import { getLocalByKey } from '@utils/helpers';

import { checkCallbackLosDisbursementUpl } from './actions';

export function* checkCallbackLosDisbursementUplSaga(action) {
  const appId = getLocalByKey('appId');

  if (appId) {
    const payload = {
      url: CONSTANT.apiCommon.applicationDetail(appId),
    };

    yield put(checkCallbackLosDisbursementUpl.request());
    try {
      const respond = yield call(Api.get, payload);
      const { data, meta } = get(respond, 'data') || {};

      if (
        get(meta, 'code') === 'IL-200' &&
        get(data, 'screen_type') !== screenType.WAITING_LOS_DISBURSEMENT_LOAN
      ) {
        yield put(checkCallbackLosDisbursementUpl.success());

        if (
          !get(action, 'payload.isNextPage') &&
          data.screen_type === screenType.COMPLETED_LOAN &&
          [stepType.COMPLETED_C_WAITING, stepType.COMPLETED_D_WAITING].includes(
            data.step_type,
          )
        ) {
          return;
        }

        nextScreen({ screen: data.screen_type });
        return;
      }

      yield put(checkCallbackLosDisbursementUpl.failure());
    } catch (error) {
      yield put(checkCallbackLosDisbursementUpl.failure());
    }
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(
      checkCallbackLosDisbursementUpl.TRIGGER,
      checkCallbackLosDisbursementUplSaga,
    ),
  ]);
}
