import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { screenType, ROUTE } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { getRelationships, submitAddSecInfo } from './actions';

export function* getRelationshipsSaga() {
  const payload = {
    url: CONSTANT.apiCommon.selections,
    params: { category: 'RELATIONSHIP' },
  };

  yield put(getRelationships.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(
        getRelationships.success({
          listRelationship: isArray(data) ? data : [],
        }),
      );
      return;
    }

    yield put(getRelationships.failure());
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(getRelationships.failure());
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export function* submitAddSecInfoSaga(action) {
  const payload = {
    url: CONSTANT.apiCc.addSecInfo,
    data: get(action, 'payload'),
  };

  yield put(submitAddSecInfo.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (
      get(meta, 'code') === 'IL-200' &&
      get(data, 'screen') === screenType.CARD_RECEIVE_ADDRESS
    ) {
      history.push(ROUTE.cc.DeliveryAddress);
      return;
    }

    yield put(submitAddSecInfo.success());
    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
  } catch (error) {
    yield put(submitAddSecInfo.failure());
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(getRelationships.TRIGGER, getRelationshipsSaga),
    takeLatest(submitAddSecInfo.TRIGGER, submitAddSecInfoSaga),
  ]);
}
