/*
 *
 * Lady Mastercard reducer
 *
 */
import { produce } from 'immer';

import { checkCallbackLosDisbursementUpl } from './actions';

export const initialState = {
  loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const WattingCheckingInfoReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case checkCallbackLosDisbursementUpl.REQUEST: {
        draft.loading = true;
        break;
      }
      case checkCallbackLosDisbursementUpl.SUCCESS: {
        draft.loading = false;
        break;
      }
      case checkCallbackLosDisbursementUpl.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });
export default WattingCheckingInfoReducer;
