/* eslint-disable no-else-return */
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import history from '@utils/history';
import { ROUTE } from '@utils/constants';
import * as Api from '@utils/services/api.request';
import * as CONST_GLB from '@utils/services/api.constants';

import {
  downloadEcontractAction,
  getFilePDFAction,
  sendOTPAction,
} from './actions';

// import * as Api from '@utils/services/api.request';

export function* sendOTPActionSaga(action) {
  const { phoneNumber } = action.payload;
  const payload = {
    url: CONST_GLB.apiCc.eContractOtp,
    params: {
      phoneNumber,
    },
  };
  yield put(sendOTPAction.request());
  try {
    const respond = yield call(Api.get, payload);
    if (respond.data.status === 200) {
      history.push(`${ROUTE.cc.OtpEcontract}?mobile=${phoneNumber}`);
    }
    // yield put(sendOTPAction.success(respond));
  } catch (error) {
    toast.error(get(error, 'message') || 'error system');
    yield put(sendOTPAction.failure(error));
  }
}

export function* downloadEcontractActionSaga(action) {
  const appId = localStorage.getItem('appId') || get(action, 'payload.appId');
  const payload = {
    url: CONST_GLB.apiCommon.getFileEContract(appId),
    params: { name: get(action, 'payload.name') },
  };
  if (appId) {
    yield put(downloadEcontractAction.request());
    try {
      const respond = yield call(Api.download, payload);
      yield put(downloadEcontractAction.success(respond));
    } catch (error) {
      yield put(downloadEcontractAction.failure(error));
    }
  }
}

export function* getFilePDFActionSaga(action) {
  const appId = localStorage.getItem('appId') || get(action, 'payload.appId');
  const payload = {
    url: CONST_GLB.apiCommon.getFileEContract(appId),
    params: { name: get(action, 'payload.name') },
    responseType: 'blob',
  };
  if (appId) {
    yield put(getFilePDFAction.request());
    try {
      const respond = yield call(Api.getFile, payload);
      const { data } = respond;
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      yield put(getFilePDFAction.success(fileURL));
    } catch (error) {
      yield put(getFilePDFAction.failure(error));
    }
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(sendOTPAction.TRIGGER, sendOTPActionSaga),
    takeLatest(getFilePDFAction.TRIGGER, getFilePDFActionSaga),
    takeLatest(downloadEcontractAction.TRIGGER, downloadEcontractActionSaga),
  ]);
}
