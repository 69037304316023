import Logo1 from '@assets/LogoFooter.svg';
import React from 'react';
import { EndofFooter, FooterFirst, FooterWraper } from './style';

export const Footer = () => (
  <FooterWraper>
    <div className="headLeft">
      <FooterFirst>
        <div className="footerFirstLayout">
          <div className="footerFirstLayout1">
            <div className="logo1">
              <img className="logo1Img" alt="" src={Logo1} />
            </div>
            <div className="vertical" />
            <div className="footer1">
              <div className="title1">Ngân hàng TMCP Việt Nam Thịnh Vượng</div>
              <div className="title2">
                <div>
                  <span className="textBlod">Trụ sở chính: </span>89 Láng Hạ,
                  Đống Đa, Hà Nội
                </div>
              </div>
            </div>
          </div>
          <div className="footer1 footerNone">
            <div className="title2">
              <div>
                <span className="textBlod">Hotline:</span> 1900 54 54 15
              </div>
              <div>
                <span className="textBlod">Email:</span> khcn@vpbank.com.vn
              </div>
            </div>
          </div>
        </div>
      </FooterFirst>
      <EndofFooter>
        <div className="footerWrap">
          <div className="endofFooter1">
            © 2022 VPBank – Bản quyền đã được bảo hộ
          </div>
          <a
            href="https://www.vpbank.com.vn/quy-dinh-bao-mat"
            target="_blank"
            className="endofFooter2"
          >
            Chính sách bảo mật
          </a>
        </div>
      </EndofFooter>
    </div>
  </FooterWraper>
);

export default Footer;
