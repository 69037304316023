import { createRoutine } from 'redux-saga-routines';

import {
  RESEND_OTP_ECONTRACT_UPL_ACTION,
  VERIFY_OTP_ECONTRACT_UPL_ACTION,
} from './constants';

export const resendOTPEcontractUplAction = createRoutine(
  RESEND_OTP_ECONTRACT_UPL_ACTION,
);
export const verifyOTPEcontractUplAction = createRoutine(
  VERIFY_OTP_ECONTRACT_UPL_ACTION,
);
