import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import size from 'lodash/size';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import * as Api from '@utils/services/api.request';
import { screenType, ROUTE } from '@utils/constants';
import * as CONSTANT from '@utils/services/api.constants';

import { confirmOcr, getOptInputIdPrefix } from './actions';

export function* getOptInputIdPrefixSaga() {
  const payload = {
    url: CONSTANT.apiCommon.getConfig,
    // 25/06/24:
    // A Sáng confirm không có gtri INPUT_ID_PREFIX trùng nhau nên chỉ cần lấy 1 size 1 và page 0
    params: {
      page: 0,
      size: 1,
      code: 'INPUT_ID_PREFIX',
    },
  };

  yield put(getOptInputIdPrefix.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data', {});

    if (get(meta, 'code') === 'IL-200' && size(data)) {
      const items = get(data, '[0].value') ? JSON.parse(data[0].value) : [];
      yield put(getOptInputIdPrefix.success({ items }));
      return;
    }

    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
    yield put(getOptInputIdPrefix.failure());
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(getOptInputIdPrefix.failure());
  }
}

export function* confirmOcrSaga(action) {
  const { anonymous } = action.payload;
  const payload = {
    url: CONSTANT.apiCommon.ocrConfirm,
    data: {
      ...get(action, 'payload'),
      anonymous: get(anonymous, 'isPrivate') ? 1 : 0,
    },
  };

  yield put(confirmOcr.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    yield put(confirmOcr.success());
    if (get(meta, 'code') === 'IL-200' && data) {
      switch (get(data, 'screen')) {
        case screenType.REJECT: {
          history.push(ROUTE.common.Reject);
          break;
        }
        case screenType.ADDITIONAL_INFO: {
          history.push(ROUTE.common.AdditionalInfo);
          break;
        }
        case screenType.ADDITIONAL_INFO_PA: {
          history.push(ROUTE.common.AdditionalInfoPA);
          break;
        }
        case screenType.WAITING_LOS_AFTER_OCR: {
          history.push(ROUTE.common.WattingLosAfterOCR);
          break;
        }
        default: {
          toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_01));
          break;
        }
      }
      return;
    }

    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
    yield put(confirmOcr.failure());
  } catch (error) {
    toast.error(get(error, 'meta.message') || 'error system');
    yield put(confirmOcr.failure());
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(confirmOcr.TRIGGER, confirmOcrSaga),
    takeLatest(getOptInputIdPrefix.TRIGGER, getOptInputIdPrefixSaga),
  ]);
}
