import { createRoutine } from 'redux-saga-routines';
import {
  CHECK_WAITING_LOS_DISBURSEMENT,
  CHECK_WATTING_GET_DETAIL_DISBURSEMENT,
  GET_STATUS_WAITING_DISBURSEMENT,
} from './constants';

export const checkWattingLosDisbursement = createRoutine(
  CHECK_WAITING_LOS_DISBURSEMENT,
);
export const checkWattingDetailDisb = createRoutine(
  CHECK_WATTING_GET_DETAIL_DISBURSEMENT,
);
export const getStatusWaitingDisb = createRoutine(
  GET_STATUS_WAITING_DISBURSEMENT,
);
