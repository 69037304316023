import get from 'lodash/get';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { redirectReEnter } from '@utils/redirect';
import * as Api from '@utils/services/api.request';
import * as CONSTANT from '@utils/services/api.constants';

import history from '@utils/history';
import { MESSAGE_SYSTEM } from '@utils/message';
import { ROUTE, typePdf, TOKEN_KEY, screenType } from '@utils/constants';

import { typeModalPdf } from './constants';
import {
  getFileEcontractUpl,
  confirmEcontractUpl,
  cancelLoanEcontractUpl,
} from './actions';

export function* getFileEcontractUplaga(action) {
  const token = localStorage.getItem(TOKEN_KEY);
  const appId = get(action, 'payload.appId', localStorage.getItem('appId'));

  if (appId && token) {
    yield put(getFileEcontractUpl.request());
    const payload = {
      url: CONSTANT.apiCommon.getFileEContract(appId),

      responseType: 'blob',
      params: {
        order: get(action, 'payload.type') === typeModalPdf.ECONTRACT ? 1 : 2,
      },
    };

    try {
      const respond = yield call(Api.getFile, payload);
      const { data, status } = respond || {};

      if (status === 200 && data) {
        const file = URL.createObjectURL(new Blob([data], typePdf));

        switch (get(action, 'payload.type')) {
          case typeModalPdf.ECONTRACT: {
            yield put(getFileEcontractUpl.success({ econtract: file }));
            return;
          }
          case typeModalPdf.INSURANCE: {
            yield put(getFileEcontractUpl.success({ insurance: file }));
            return;
          }
          default: {
            toast.error(MESSAGE_SYSTEM.FILE_NOTFOUND);
            break;
          }
        }
      }

      yield put(getFileEcontractUpl.failure());
    } catch (error) {
      yield put(getFileEcontractUpl.failure());
    }
  }
}

export function* cancelLoanEcontractUplSaga(action) {
  const payload = {
    url: CONSTANT.apiUpl.cancelEcontractLoan,
    data: null,
  };

  yield put(cancelLoanEcontractUpl.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200') {
      yield put(cancelLoanEcontractUpl.success());
      redirectReEnter('UPL');
      return;
    }

    toast.error(get(data, 'message', MESSAGE_SYSTEM.error_02));
    yield put(cancelLoanEcontractUpl.failure());
  } catch (error) {
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
    yield put(cancelLoanEcontractUpl.failure());
  }
}

export function* confirmEcontractUplSaga(action) {
  const payload = {
    url: CONSTANT.apiUpl.eContractOtp,
    params: get(action, 'payload', {}),
  };

  yield put(confirmEcontractUpl.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200') {
      yield put(confirmEcontractUpl.success());

      switch (get(data, 'screen')) {
        case screenType.VERIFY_OTP_ECONTRACT: {
          history.push(
            `${ROUTE.upl.OtpEcontract}?mobile=${get(
              action,
              'payload.phoneNumber',
              '',
            )}`,
          );
          return;
        }
        case screenType.OUT_SIDE_WORKING_HOUR: {
          history.push(ROUTE.upl.OutSideWorkingHour);
          return;
        }
        default: {
          toast.error(get(data, 'message', MESSAGE_SYSTEM.error_01));
          break;
        }
      }
      return;
    }

    toast.error(get(meta, 'message', MESSAGE_SYSTEM.error_02));
    yield put(confirmEcontractUpl.failure());
  } catch (error) {
    toast.error(get(error, 'meta.message', MESSAGE_SYSTEM.default));
    yield put(confirmEcontractUpl.failure());
  }
}

export default function* watchAll() {
  yield all([
    takeEvery(getFileEcontractUpl.TRIGGER, getFileEcontractUplaga),
    takeLatest(confirmEcontractUpl.TRIGGER, confirmEcontractUplSaga),
    takeLatest(cancelLoanEcontractUpl.TRIGGER, cancelLoanEcontractUplSaga),
  ]);
}
