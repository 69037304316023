import getLd from 'lodash/get';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import history from '@utils/history';
import { isIOS, getLinkReEnter, copyToClipboard } from '@utils/helpers';
import { screenType, ROUTE } from '@utils/constants';

import { getInfoCustomerDetail } from '@App/actions';

export function useLogicOCRChangeDevice() {
  const dispatch = useDispatch();

  const { appDetail } = useSelector(state => getLd(state, 'global', {}));

  const [isOpenModalChangeDevice, setOpenModalChangeDevice] = useState(false);
  const [isOpenModalChangeBrowser, setOpenModalChangeBrowser] = useState(false);

  const onCopy = () => {
    copyToClipboard(getLinkReEnter(localStorage.getItem('productType')));
    toast.success('Liên kết đã được sao chép');
  };

  const handleOpenModalChangeDevice = () =>
    setOpenModalChangeDevice(!isOpenModalChangeDevice);

  const handleOpenModalChangeBrowser = () =>
    setOpenModalChangeBrowser(!isOpenModalChangeBrowser);

  const onBackScreen = () => {
    switch (getLd(appDetail, 'screen_type')) {
      case screenType.OCR_GUIDE: {
        return history.push(ROUTE.common.OCRInstruction);
      }
      case screenType.LIVE_NESS_GUIDE: {
        return history.push(ROUTE.common.Liveness);
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(getInfoCustomerDetail());
    }, 100);
  }, []);

  return {
    isOpenModalChangeDevice,
    isOpenModalChangeBrowser,

    isIOS: isIOS(),
    isDisable: !(
      getLd(appDetail, 'screen_type') === screenType.OCR_GUIDE ||
      getLd(appDetail, 'screen_type') === screenType.LIVE_NESS_GUIDE
    ),

    onCopy,
    onBackScreen,
    handleOpenModalChangeDevice,
    handleOpenModalChangeBrowser,
  };
}
