import { produce } from 'immer';
import get from 'lodash/get';
import { getAddress } from './actions';

export const initialState = {
  loadingPage: false,
  address: null,
};

/* eslint-disable default-case, no-param-reassign */
const RegisterCompletedReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAddress.REQUEST: {
        draft.loadingPage = true;
        break;
      }
      case getAddress.SUCCESS: {
        draft.loadingPage = false;
        draft.address = get(action, 'payload.address_detail');
        break;
      }
      case getAddress.FAILURE: {
        draft.loadingPage = false;
        break;
      }
    }
  });

export default RegisterCompletedReducer;
