import { createGlobalStyle } from 'styled-components';
import SVNGilroyMedium from '@fonts/SVN-Gilroy Medium.otf';
const { innerHeight: height } = window;
const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    color: #4a4a4a;
    font-size: 13px;
    @font-face {
      font-family: 'SVN-Gilroy';
      src: url(${SVNGilroyMedium}) format('opentype');
    }
    background:#F2F5F6
  }

  html {
    body {
      font-family: 'SVN-Gilroy';
      font-size: 13px;
    }
  }

  #app {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .non-touch {
    pointer-events: none;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  a, button {
    &:focus, &:active {
      outline: 0;
    }
  }
  .container{
    @media only screen and (min-width: 961px) {
      width: 780px;
      margin: auto;
      margin-top:40px;
    }
    @media only screen and (max-width: 961px) {
      width: 100%;
      margin: auto;
    }
  }
  img {
    max-width: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .errorText {
    color: red;
    font-size: 11px;
  }

  .pageTitle {
    margin: 0 0 25px;
    font-size: 24px;
    line-height: 32px;
    color: #111827;
  }
  .inputOtp0 >input{
    margin-left: 0px !important;
  }
  .inputOtp5 >input{
    margin-right: 0px !important;
  }
  .rc-anchor-error-msg{
    display: none !important;
  }
  .recaptcha-checkbox-expired .recaptcha-checkbox-border, .recaptcha-checkbox-expired .recaptcha-checkbox-spinner-gif{
    border: 2px solid #C1C1C1;
    border-radius: 1px;
  }
  .MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  .textLinearGradient{
    background: linear-gradient(91.03deg, #00B74F 0%, #009540 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .dialogCustom{
    .MuiBackdrop-root{
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
    .MuiDialog-container{
      .MuiPaper-root{
        min-width: 320px;
        background: #FFFFFF;
        border-radius: 4px 4px 0px 0px;
        box-shadow: none;
        margin:auto;
        @media only screen and (max-width: 580px) {
          bottom: 0px;
          width: 100%;
          position: absolute;
        }
        
        h2.MuiTypography-root{
          font-family: 'SVN-Gilroy';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #104370;
          text-align: center;
          padding: 0 16px;
          gap: 5px;
          display: flex;
          align-items: center;

          .titlePopup{
            flex: 1;
            padding: 20px 0px;
          }
          .btnClosePopup {
            width: 20px;
            height: 20px;
            padding: 0;
            min-width: auto;
          }
        }
        .MuiDialogContent-root{
          border-top: 1px solid #CDD7E2;
          padding: 0;
        }
      }
    }
  }
  .autoSuggestionItem {
    color: #092f51;
    cursor: pointer;
    font-size: 14px;
    padding: 3px 20px;
    font-family: SVN-Gilroy;
    &:hover {
      background: #bfbaba1c;
    }
  }
  .Mui-error.MuiFormHelperText-contained {
    color: #f50700cf !important;;
    font-size: 11px;
    margin-left: 11px;
    margin-right: 11px;
    font-family: 'SVN-Gilroy';
  }
  ._codeError {
    right: 0;
    bottom: 0;
    opacity: 0.2;
    color: #092F51;
    font-size: 14px;
    position: fixed;
    font-weight: 500;
    line-height: 15px;
    z-index: 100000000000;
    font-family: 'SVN-Gilroy';
    font-family: SVN-Gilroy;
  }
  .btnFixed{
    margin: auto;
    width: 100%;
    display: contents;
    @media (max-width: 580px) {
      position: fixed;
      bottom: 20px;
      left: 16px;
      right: 16px;
      display: ${height < 680 ? 'contents' : 'block'} ;
      width: auto;
      @media (max-height: 680px) {
        position: unset;
      }
    }
  }


  .MuiDialogContent-root::-webkit-scrollbar {
    height: 1px;
  }

  .MuiDialogContent-root::-webkit-scrollbar {
    width: 5px;
  }
  .MuiDialogContent-root::-webkit-scrollbar-track {
    background-color: white;
  }
  .MuiDialogContent-root::-webkit-scrollbar-thumb {
    background: var(--navy-100, #cdd7e2);
    border-radius: 10px;
    width: 2px;
  }

  .justify-between{
    justify-content: space-between
  }
`;

export default GlobalStyle;
