import { produce } from "immer";
import getLd from "lodash/get";

import { getImageCard } from "@utils/helpers";
import {
  getTowers,
  getCheckCa,
  getDistricts,
  getProvinces,
  getCardDesign,
  getWorkTimeApp,
  getCountryAction,
  getSettingSdkOCR,
  closeModalCheckCa,
  updateApplication,
  getInfoCustomerDetail,
  createNiceDigitalAccountAction,
} from "./actions";

export const initialState = {
  loading: false,
  flagCheckCA: false,
  loadingCountry: false,
  isLoadingCardDesign: false,

  appDetail: {},
  dataCheckCa: {},
  settingSDKOCR: {},
  dataCardDesign: {},

  listTower: [],
  listCountry: [],
  listProvince: [],
  listDistrict: [],

  workTime: {
    mon2Fri: '', // 11/12/24 A Sáng confirm giá trị giống field text ILCMS-1301
    text: '',
    sat: '',
  },
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  // eslint-disable-next-line consistent-return
  produce(state, (draft) => {
    switch (action.type) {
      case getInfoCustomerDetail.REQUEST:
        draft.loading = true;
        break;
      case getInfoCustomerDetail.SUCCESS:
        draft.loading = false;
        draft.appDetail = getLd(action, "payload.appDetail") || {};
        break;
      case getInfoCustomerDetail.FAILURE:
        draft.loading = false;
        draft.appDetail = {};
        break;

      case getCountryAction.REQUEST: {
        draft.loadingCountry = true;
        break;
      }
      case getCountryAction.SUCCESS: {
        draft.loadingCountry = false;
        draft.listCountry =
          action.payload.data.map((item) => ({
            value: item.id,
            label: item.vi,
          })) || [];
        break;
      }
      case getCountryAction.FAILURE: {
        draft.loadingCountry = false;
        break;
      }
      case updateApplication.REQUEST:
        draft.loading = true;
        break;
      case updateApplication.SUCCESS:
        draft.loading = false;
        break;
      case updateApplication.FAILURE:
        draft.loading = false;
        break;

      case getCheckCa.REQUEST:
        // draft.loading = true;
        // draft.flagCheckCA = false;
        draft.flagCheckCA = true;

        break;
      // case getCheckCa.SUCCESS:
      //   draft.loading = false;
      //   draft.dataCheckCa = getLd(action, 'payload.data.data');
      //   draft.flagCheckCA = true;
      //   break;
      // case getCheckCa.FAILURE:
      //   draft.loading = false;
      //   draft.flagCheckCA = false;
      //   break;
      case getCardDesign.REQUEST:
        // draft.flagCheckCA = false;
        draft.isLoadingCardDesign = true;
        draft.dataCardDesign = [];
        break;
      case getCardDesign.SUCCESS:
        draft.isLoadingCardDesign = false;
        draft.dataCardDesign = getLd(action, "payload.data.lstOutput").map(
          (item) => ({
            id: item.id,
            name: item.cardDesignImageName,
            image: getImageCard(item.cardDesignImageId),
            templateCode: item.templateCode,
          })
        );
        break;
      case getCardDesign.FAILURE:
        draft.isLoadingCardDesign = false;

        break;
      case createNiceDigitalAccountAction.REQUEST:
        draft.loading = true;
        break;
      case createNiceDigitalAccountAction.SUCCESS:
        draft.loading = false;
        break;
      case createNiceDigitalAccountAction.FAILURE:
        draft.loading = false;
        break;
      case getTowers.REQUEST: {
        draft.loading = true;
        draft.listTower = [];
        break;
      }
      case getTowers.SUCCESS: {
        draft.loading = false;
        draft.listTower = getLd(action, "payload.listTower");
        break;
      }
      case getTowers.FAILURE: {
        draft.loading = false;
        draft.listTower = [];
        break;
      }

      case getDistricts.REQUEST: {
        draft.loading = true;
        draft.listDistrict = [];
        break;
      }
      case getDistricts.SUCCESS: {
        draft.loading = false;
        draft.listDistrict = getLd(action, "payload.listDistrict");
        break;
      }
      case getDistricts.FAILURE: {
        draft.loading = false;
        draft.listDistrict = [];
        break;
      }

      case getProvinces.REQUEST: {
        draft.loading = true;
        draft.listProvince = [];
        break;
      }
      case getProvinces.SUCCESS: {
        draft.loading = false;
        draft.listProvince = getLd(action, "payload.listProvince");
        break;
      }
      case getProvinces.FAILURE: {
        draft.loading = false;
        draft.listProvince = [];
        break;
      }

      // getSettingSdkOCR
      case getSettingSdkOCR.REQUEST: {
        draft.loading = true;
        break;
      }
      case getSettingSdkOCR.SUCCESS: {
        draft.loading = false;
        draft.settingSDKOCR = getLd(action, "payload", {});
        break;
      }
      case getSettingSdkOCR.FAILURE: {
        draft.loading = false;
        draft.settingSDKOCR = {};
        break;
      }

      // getWorkTimeApp
      case getWorkTimeApp.REQUEST: {
        draft.loading = true;
        break;
      }
      case getWorkTimeApp.SUCCESS: {
        draft.loading = false;
        draft.workTime = getLd(action, "payload", {});
        break;
      }
      case getWorkTimeApp.FAILURE: {
        draft.loading = false;
        break;
      }

      // closeModalCheckCa
      case closeModalCheckCa.REQUEST: {
        draft.flagCheckCA = false;
        break;
      }
    }
  });
export default appReducer;
