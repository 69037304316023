/* eslint-disable prettier/prettier */
import { createRoutine } from 'redux-saga-routines';

import * as CONSTANT from './constants';

export const getTowers = createRoutine(CONSTANT.GET_TOWERS);

export const getCheckCa = createRoutine(CONSTANT.GET_CHECK_CA);

export const getDistricts = createRoutine(CONSTANT.GET_DISTRICTS);

export const getProvinces = createRoutine(CONSTANT.GET_PROVINCES);

export const getCountryAction = createRoutine(CONSTANT.GET_COUNTRY);

export const getCardDesign = createRoutine(CONSTANT.GET_CARD_DESIGN);

export const updateApplication = createRoutine(CONSTANT.UPDATE_APPLICATION);

export const getSettingSdkOCR = createRoutine(CONSTANT.GET_SETTING_SDK_OCR);

export const closeModalCheckCa = createRoutine(CONSTANT.CLOSE_MODAL_CHECK_CA);

export const getInfoCustomerDetail = createRoutine(
  CONSTANT.GET_INFO_CUSTOMER_DETAIL,
);

export const createNiceDigitalAccountAction = createRoutine(
  CONSTANT.CREATE_NICE_DIGITAL_ACCOUNT,
);

export const getWorkTimeApp = createRoutine(CONSTANT.GET_WORK_TIME_APP);
