/*
 *
 * Lady Mastercard reducer
 *
 */
import { produce } from 'immer';
import get from 'lodash/get';
import {
  sendOTPAction,
  getFilePDFAction,
  downloadEcontractAction,
} from './actions';

export const initialState = {
  flagRegister: false,
  fileViewPDF: false,
  isLoadingSendOTP: false,
  isLoadingViewFile: false,
  isLoadingDownloadEcontract: false,
};

/* eslint-disable default-case, no-param-reassign */
const RegisterReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case sendOTPAction.REQUEST: {
        draft.isLoadingSendOTP = true;
        break;
      }
      case sendOTPAction.SUCCESS: {
        draft.isLoadingSendOTP = false;
        break;
      }
      case sendOTPAction.FAILURE: {
        draft.isLoadingSendOTP = false;
        break;
      }
      case downloadEcontractAction.REQUEST: {
        draft.isLoadingDownloadEcontract = true;
        break;
      }
      case downloadEcontractAction.SUCCESS: {
        draft.isLoadingDownloadEcontract = false;
        break;
      }
      case downloadEcontractAction.FAILURE: {
        draft.isLoadingDownloadEcontract = false;
        break;
      }

      case getFilePDFAction.REQUEST: {
        draft.isLoadingViewFile = true;
        break;
      }
      case getFilePDFAction.SUCCESS: {
        draft.isLoadingViewFile = false;
        draft.fileViewPDF = get(action, 'payload');
        break;
      }
      case getFilePDFAction.FAILURE: {
        draft.isLoadingViewFile = false;
        break;
      }
    }
  });
export default RegisterReducer;
