import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import * as Api from '@utils/services/api.request';
import { nextScreen } from '@utils/redirect';
import { screenType } from '@utils/constants';
import { getLocalByKey } from '@utils/helpers';
import * as CONST_GLB from '@utils/services/api.constants';

import {
  checkWattingDetailDisb,
  checkWattingLosDisbursement,
  getStatusWaitingDisb,
} from './actions';

export function* checkWattingLosDisbursementSaga() {
  const payload = {
    url: CONST_GLB.apiCc.checkWtLosDisb,
  };

  yield put(checkWattingLosDisbursement.request());
  try {
    const respond = yield call(Api.get, payload);
    const { data, meta } = get(respond, 'data') || {};
    if (get(meta, 'code') === 'IL-200' && data) {
      yield put(checkWattingLosDisbursement.success(respond));
    } else {
      yield put(checkWattingLosDisbursement.failure());
    }
  } catch (error) {
    yield put(checkWattingLosDisbursement.failure());
  }
}

export function* checkWattingDetailDisbSaga() {
  const appId = getLocalByKey('appId');
  if (appId) {
    const payload = {
      url: CONST_GLB.apiCommon.applicationDetail(appId),
    };
    yield put(checkWattingDetailDisb.request());
    try {
      const respond = yield call(Api.get, payload);
      const { data, meta } = get(respond, 'data') || {};

      if (get(meta, 'code') === 'IL-200' && data) {
        if (
          ![
            screenType.WAITING_LOS_DISBURSEMENT,
            screenType.COMPLETED_C_30,
            screenType.COMPLETED_D_30,
            screenType.COMPLETED_E_30,
          ].includes(get(data, 'screen_type'))
        ) {
          nextScreen({ screen: get(data, 'screen_type') });
        }
        yield put(checkWattingDetailDisb.success(respond));
      } else {
        yield put(checkWattingDetailDisb.failure());
      }
    } catch (error) {
      yield put(checkWattingDetailDisb.failure());
    }
  }
}

export function* getStatusWaitingDisbSaga() {
  const appId = getLocalByKey('appId');
  if (appId) {
    const payload = {
      url: CONST_GLB.apiCc.getStatusDisbursement,
    };
    yield put(getStatusWaitingDisb.request());
    try {
      const respond = yield call(Api.get, payload);
      const { data, meta } = get(respond, 'data') || {};

      if (get(meta, 'code') === 'IL-200' && data) {
        if (
          [
            screenType.COMPLETED_A,
            screenType.COMPLETED_B,
            screenType.COMPLETED_C,
            screenType.COMPLETED_D,
            screenType.COMPLETED_E,
            screenType.COMPLETED_C_30,
            screenType.COMPLETED_D_30,
            screenType.COMPLETED_E_30,
          ].includes(get(data, 'screen'))
          // BE(anh Hào anh Sáng confirm api này lúc nào cũng có key điều hướng màn hình)
        ) {
          nextScreen({ screen: get(data, 'screen') });
        }
        yield put(getStatusWaitingDisb.success(respond));
      } else {
        yield put(getStatusWaitingDisb.failure());
      }
    } catch (error) {
      yield put(getStatusWaitingDisb.failure());
    }
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(
      checkWattingLosDisbursement.TRIGGER,
      checkWattingLosDisbursementSaga,
    ),
    takeLatest(checkWattingDetailDisb.TRIGGER, checkWattingDetailDisbSaga),
    takeLatest(getStatusWaitingDisb.TRIGGER, getStatusWaitingDisbSaga),
  ]);
}
