import { ROUTE } from '@utils/constants';

import * as PageUPL from '@containers/UPL';

const routerUpl = [
  {
    exact: true,
    path: ROUTE.upl.LoanProfile,
    component: PageUPL.LoanProfileUpl,
  },
  {
    exact: true,
    path: ROUTE.upl.Overdue,
    component: PageUPL.OverdueUpl,
  },
  {
    exact: true,
    path: ROUTE.upl.LoanOffer,
    component: PageUPL.LoanOfferUpl,
  },
  {
    exact: true,
    path: ROUTE.upl.WaitingProcessGenForm,
    component: PageUPL.WattingProcessGenFormUpl,
  },
  {
    exact: true,
    path: ROUTE.upl.OutSideWorkingHour,
    component: PageUPL.OutSideWorkingHourUpl,
  },
  {
    exact: true,
    path: ROUTE.upl.WaitingCheckingInfo,
    component: PageUPL.WaitingCheckingInfoUpl,
  },
  {
    exact: true,
    path: ROUTE.upl.EcontractLoan,
    component: PageUPL.EcontractLoanUpl,
  },
  {
    exact: true,
    path: ROUTE.upl.OtpEcontract,
    component: PageUPL.OtpEcontractUpl,
  },
  {
    exact: true,
    path: ROUTE.upl.Completed,
    component: PageUPL.CompletedUPL,
  },
  {
    exact: true,
    path: ROUTE.upl.WaitingLosDisbursement,
    component: PageUPL.WattingDisbursementUpl,
  },
  {
    exact: true,
    path: ROUTE.upl.CompleteProfile,
    component: PageUPL.CompleteProfileUpl,
  },
];

export default routerUpl;
