/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { produce } from 'immer';
import get from 'lodash/get';
import { getRemindAction, submitRemindAction } from './actions';

export const initialState = {
  loading: false,
  listRemind: [],
};

const IntroductionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getRemindAction.REQUEST: {
        draft.loading = true;
        draft.listRemind = [];
        break;
      }
      case getRemindAction.SUCCESS: {
        draft.loading = false;
        draft.listRemind = get(action, 'payload.listRemind');
        break;
      }
      case getRemindAction.FAILURE: {
        draft.loading = false;
        draft.listRemind = [];
        break;
      }
      case submitRemindAction.REQUEST: {
        draft.loading = true;
        break;
      }
      case submitRemindAction.SUCCESS: {
        draft.loading = false;
        break;
      }
      case submitRemindAction.FAILURE: {
        draft.loading = false;
        break;
      }
    }
  });
export default IntroductionReducer;
