import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import { checkSafari } from '@utils/helpers';
import * as Api from '@utils/services/api.request';
import * as CONST_GLB from '@utils/services/api.constants';

import { getCutOfftimeAction, getLinkThinhPhat } from './actions';
import { LAYOUT, VIDEO_CALL_TPC } from './constants';

export function* getCutOfftimeActionSaga(action) {
  const payload = {
    url: CONST_GLB.apiCommon.validationTimeWork,
    params: { productType: get(action, 'payload.productType', null) },
  };

  try {
    yield put(getCutOfftimeAction.request());
    const respond = yield call(Api.get, payload);

    // Trạng thái trả về true =>  vẫn trong giờ làm việc
    if (get(respond, "data.data")) {
      yield put(getCutOfftimeAction.success({
        layout: LAYOUT.INTRODUCTION,
      }));
      return;
    }

    yield put(getCutOfftimeAction.success({
      layout: LAYOUT.OUTSIDE_WORK_HOUR,
    }));
  } catch (error) {
    yield put(getCutOfftimeAction.failure(error));
  }
}

export function* getLinkThinhPhatActionSaga(action) {
  const payload = {
    url: CONST_GLB.apiCommon.getLinkWs6,
    data: get(action, 'payload'),
  };
  yield put(getLinkThinhPhat.request());
  try {
    const respond = yield call(Api.post, payload);
    const { data, meta } = get(respond, 'data') || {};

    if (get(meta, 'code') === 'IL-200' && data) {
      const isSafari = checkSafari();
      if (data.key === VIDEO_CALL_TPC) {
        if (isSafari) {
          window.location.href = data.message;
        } else {
          window.open(data.message, '_blank');
        }
      }
      yield put(getLinkThinhPhat.success(respond));
    } else {
      yield put(getLinkThinhPhat.failure());
    }
  } catch (error) {
    yield put(getLinkThinhPhat.failure(error));
  }
}

export default function* watchAll() {
  yield all([takeLatest(getCutOfftimeAction.TRIGGER, getCutOfftimeActionSaga)]);
  yield all([takeLatest(getLinkThinhPhat.TRIGGER, getLinkThinhPhatActionSaga)]);
}
